import { useMemo } from 'react';
import axios from 'axios';
import { useToken } from '../contexts/ApiTokenProvider';

const useAxiosWithToken = () => {
  const { token, acquireToken } = useToken();

  // Memoize the Axios instance to avoid re-creating it on every render
  const axiosInstance = useMemo(() => {
    const instance = axios.create({
      baseURL: '/api/',
    });

    instance.interceptors.request.use(
      async (config) => {
        console.debug('Request started');
        if (!token) {
          await acquireToken();
          config.headers['Authorization'] = `Bearer ${token}`;
        } else {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    instance.interceptors.response.use(
      (response) => {
        console.debug('Response received');
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            await acquireToken();
            originalRequest.headers['Authorization'] = `Bearer ${token}`;
            return instance(originalRequest);
          } catch (err) {
            return Promise.reject(err);
          }
        }
        return Promise.reject(error);
      }
    );

    return instance;
  }, [token, acquireToken]);

  return axiosInstance;
};

export default useAxiosWithToken;
