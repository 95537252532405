const deskConfig6OG_C = [
    { id: '53', name: 'C: 1', coords: '200,540,35,25' },
    { id: '54', name: 'C: 2', coords: '235,540,35,25' },
    { id: '55', name: 'C: 3', coords: '200,565,35,25' },
    { id: '56', name: 'C: 4', coords: '235,565,35,25' },
    { id: '57', name: 'C: 5', coords: '195,455,35,25' },
    { id: '58', name: 'C: 6', coords: '230,455,35,25' },
    { id: '59', name: 'C: 7', coords: '195,480,35,25' },
    { id: '60', name: 'C: 8', coords: '230,480,35,25' },
    { id: '61', name: 'C: 9', coords: '192,260,35,25' },
    { id: '62', name: 'C: 10', coords: '227,260,35,25' },
    { id: '63', name: 'C: 11', coords: '192,285,35,25' },
    { id: '64', name: 'C: 12', coords: '227,285,35,25' },
    { id: '65', name: 'C: 13', coords: '195,190,30,20' },
    { id: '66', name: 'C: 14', coords: '230,190,30,20' },
    { id: '67', name: 'C: 15', coords: '190,120,35,25' },
    { id: '68', name: 'C: 16', coords: '225,120,35,25' },
    { id: '69', name: 'C: 17', coords: '190,145,35,25' },
    { id: '70', name: 'C: 18', coords: '225,145,35,25' },
    { id: '71', name: 'C: 19', coords: '195,35,35,25' },
    { id: '72', name: 'C: 20', coords: '230,35,35,25' },
    { id: '73', name: 'C: 21', coords: '195,60,35,25' },
    { id: '74', name: 'C: 22', coords: '230,60,35,25' },
    { id: '75', name: 'C: 23', coords: '10,115,35,25' },
    { id: '76', name: 'C: 24', coords: '45,115,35,25' },
    { id: '77', name: 'C: 25', coords: '10,140,35,25' },
    { id: '78', name: 'C: 26', coords: '45,140,35,25' },
    { id: '79', name: 'C: 27', coords: '10,190,35,25' },
    { id: '80', name: 'C: 28', coords: '45,190,35,25' },
    { id: '81', name: 'C: 29', coords: '10,215,35,25' },
    { id: '82', name: 'C: 30', coords: '45,215,35,25' },
    { id: '83', name: 'C: 31', coords: '20,270,30,20' },
    { id: '84', name: 'C: 32', coords: '50,270,30,20' }
]

export default deskConfig6OG_C