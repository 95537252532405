import React, { useContext, useCallback, useEffect, useState } from 'react';
//import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { Button, Section, Form } from 'react-bulma-components';
import useAreaSelection from '../../hooks/useAreaSelection'
import useRepeatCycle from '../../hooks/useFrequency';
import useDaySelection from '../../hooks/useDaySelection';
import { validateAdminBookingForm, prepareBookingRequestsForAdmin, formatDateUI, formatDateISO } from '../../utils/utils';
import AreaSelect from '../BookingForm/AreaSelect';
import FrequencySelect from '../BookingForm/FrequencySelect';
import DaySelect from '../BookingForm/DaySelect';
import DurationSelect from '../BookingForm/DurationSelect';
import ErrorModal from '../BookingForm/ErrorModal';
import { useMsal } from "@azure/msal-react";
import { confirmAlert } from 'react-confirm-alert';

import { TokenContext } from "../../contexts/TokenContext";
import useAxiosWithToken from '../../hooks/axiosTokenHook';

import SearchInput from '../../services/SearchInput';
import FloorPlanSwitcher from '../BookingForm/FloorplanSwitcher';

const BookingForm = React.memo(({ areas, setFormVisible }) => {
  //Loading progress bar state
  const [isLoading, setIsLoading] = useState(false);

  const token = useContext(TokenContext);
  const axios = useAxiosWithToken();
  const { accounts } = useMsal();
  const userAccount = accounts[0] || null;
  const user = userAccount ? userAccount.username : "No User";

  //  const navigate = useNavigate();

  const [allBookings, setAllBookings] = useState([]);
  const [errors, setErrors] = useState([]);

  const [checkedDays, setCheckedDays] = useState([false, false, false, false, false, false, false]);
  const { handleDayToggle } = useDaySelection(checkedDays, setCheckedDays);
  const { area, areaTitle, handleArea, listAreas } = useAreaSelection(areas);
  const { frequency, handleFrequency } = useRepeatCycle();
  const [daysToBook, setDaysToBook] = useState([]);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [duration, setDuration] = useState(1);
  const [endDate, setEndDate] = useState(formatDateUI(moment()));

  const [combinedRequests, setCombinedRequests] = useState([]);
  const [bookingErrorTitle, setBookingErrorTitle] = useState([]);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [bookingErrors, setBookingErrors] = useState([]);

  const [bookingDates, setBookingDates] = useState([]);
  const [deskSelectionError, setDeskSelectionError] = useState(false);
  const [table, setTable] = useState();

  const [inputText, setInputText] = useState(() => {
    bookingDates.forEach(bookingDate => {
      const sessionValue = sessionStorage.getItem(`bookingInput_${table}_${bookingDate}`);
      return sessionValue || user || "";
    });
  });

  const [comment, setComment] = useState(() => {
    bookingDates.forEach(bookingDate => {
      const sessionValue = sessionStorage.getItem(`bookingInput_comment_${table}_${bookingDate}`);
      return sessionValue || "";
    });
  });

  useEffect(() => {
    if (inputText !== undefined) {
      bookingDates.forEach(date => {
        sessionStorage.setItem(`bookingInput_${table}_${date}`, inputText);
      })
    }
  }, [inputText, bookingDates, table]);

  useEffect(() => {
    if (comment !== undefined) {
      bookingDates.forEach(date => {
        sessionStorage.setItem(`bookingInput_comment_${table}_${date}`, comment);
      })
    }
  }, [comment, bookingDates, table]);

  const calculateBookingDates = (selectedStartDate) => {
    setBookingDates([]);
    let currentDay = moment(selectedStartDate).startOf('day');
    const checkedDaysIndexes = checkedDays.map((day, index) => day ? index : -1).filter(index => index !== -1);
    let firstBookingDay = findFirstBookingDay(currentDay, checkedDaysIndexes);
    let totalBookingDays = duration * checkedDaysIndexes.length;
    let processedDays = 0;

    while (bookingDates.length < totalBookingDays) {
      let currentWeek = Math.floor(processedDays / checkedDaysIndexes.length);
      checkedDaysIndexes.forEach(dayIndex => {
        let bookingDay = firstBookingDay.clone().isoWeekday(dayIndex + 1).add(currentWeek * frequency * 7, 'days');
        if (bookingDay.isSameOrAfter(firstBookingDay) && bookingDates.length < totalBookingDays) {
          bookingDates.push(formatDateISO(bookingDay));
        }
      });
      processedDays += checkedDaysIndexes.length;
    }

    return bookingDates;
  }

  const findFirstBookingDay = (startDate, checkedDaysIndexes) => {
    let firstDay = startDate.clone();

    // Check if checkedDaysIndexes is empty or contains no valid weekdays
    if (checkedDaysIndexes.length === 0 || !checkedDaysIndexes.some(index => index >= 0 && index < 7)) {
      // Return the original start date or a default value if no valid weekdays are found
      return startDate;
    }

    while (!checkedDaysIndexes.includes(firstDay.isoWeekday() - 1)) {
      firstDay.add(1, 'day');
    }
    return firstDay;
  }

  const handleErrorModalClose = async (proceed) => {
    setShowErrorModal(false); // Close the modal
    if (proceed) {
      await fetchAllBookings();
      proceedWithBooking();
      confirmAlert({
        title: 'Booking successfull',
        message: `All bookings done.`,
        buttons: [
          {
            label: 'OK',
            onClick: () => { }
          }
        ]
      });
    } else {
      confirmAlert({
        title: 'Booking aborted',
        message: `Your booking request was cancelled. No desk has been booked.`,
        buttons: [
          {
            label: 'OK',
            onClick: () => { }
          }
        ]
      });
    }
    setFormVisible(false);
  };

  useEffect(() => {
    const newBookingDates = calculateBookingDates(selectedDate);
    setDaysToBook(newBookingDates)

    // Update the end date based on the new booking dates
    let newEndDate = newBookingDates.length > 0 ? newBookingDates[newBookingDates.length - 1] : undefined;
    newEndDate = formatDateUI(moment(newEndDate));
    setEndDate(newEndDate);
  }, [duration, selectedDate, frequency, checkedDays]);

  const fetchAllBookings = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get('bookings');
      setAllBookings(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchAllBookings();
  }, [fetchAllBookings]);

  const [isCombinedRequestsReady, setIsCombinedRequestsReady] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  useEffect(() => {
    if (combinedRequests.length > 0 && isCombinedRequestsReady === false && !hasErrors) {
      setIsCombinedRequestsReady(true);
      proceedWithBooking();
      setIsCombinedRequestsReady(false);
    }
    if (hasErrors && bookingErrors.length > 0) {
      setShowErrorModal(true);
    }
  }, [combinedRequests, hasErrors]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let username = undefined;
    if (inputText !== undefined) {
      username = inputText;
    }

    const formErrors = validateAdminBookingForm(area, table, checkedDays, username);
    if (!table) {
      setDeskSelectionError(true);
      return;
    } else {
      setDeskSelectionError(false);
    }

    if (Object.keys(formErrors).length === 0) {
      await fetchAllBookings();
      const bookingDates = daysToBook;
      let combinedErrors = [];
      let allRequests = [];
      let deskToBook = table.id;
      bookingDates.forEach(bookingDate => {
        const { requests, bookingErrors } = prepareBookingRequestsForAdmin(username, deskToBook, bookingDate, allBookings, comment, user);
        combinedErrors = [...combinedErrors, ...bookingErrors];
        allRequests = [...allRequests, ...requests];
      });
      setCombinedRequests([...combinedRequests, ...allRequests]);
      if (combinedErrors.length > 0) {
        axios.get(`desks?deskid=${deskToBook}`)
          .then(({ data }) => {
            if (data) {
              setBookingErrorTitle(`Area ${areaTitle}, Desk Nr ${data.title}`);
            } else {
              console.log('Desk not found');
            }
          })
          .catch((error) => {
            console.error(error);
          });

        setBookingErrors([...combinedErrors]);
        setHasErrors(true);
      } else {
        setHasErrors(false);
      }
    } else {
      setErrors(formErrors);
    }
  };

  const handleErrorModalReturn = async () => {
    await fetchAllBookings();
    setShowErrorModal(false); // Close the modal
  };

  const proceedWithBooking = async () => {
    // Process each booking request and handle errors individually
    const responses = await Promise.all(combinedRequests.map(request =>
      axios.post('bookings', request)
        .catch(error => ({ error }))
    ));

    // Filter out successful responses and handle errors
    const submissionErrors = responses.filter(response => response.error);
    if (submissionErrors.length > 0) {
      confirmAlert({
        title: 'Booking Attention!',
        message: `There is already a booking by someone else. Please reload the page.`,
        buttons: [
          {
            label: 'OK',
            onClick: () => { }
          }
        ]
      });
    } else {
      confirmAlert({
        title: 'Booking successfull',
        message: `Multiple bookings were successful.`,
        buttons: [
          {
            label: 'OK',
            onClick: () => { }
          }
        ]
      });

      await fetchAllBookings();
    }
  };

  const handleAreaClick = (desk) => {
    let bookedDesks = new Set(allBookings.filter(booking => booking.id)
      .filter(booking => booking.date === formatDateISO(selectedDate))
      .map(booking => String(booking.deskid)));
    const isBooked = bookedDesks.has(String(desk.id));
    if (isBooked) {
      confirmAlert({
        title: 'Attention!',
        message: `This desk is already booked.`,
        buttons: [
          {
            label: 'OK',
            onClick: () => { setTable(''); }
          }
        ]
      });

      return;
    } else {
      setTable(desk);
    }
  };

  return (
    <>
      {isLoading && <progress className="progress is-large is-info" max="100">Loading...</progress>}
      <>
        <Section>
          <form onSubmit={handleSubmit} className="form">
            <div className="columns is-multiline">
              <div className="column is-full">
                <DaySelect checkedDays={checkedDays} handleDayToggle={handleDayToggle} error={errors['days']} onDateChange={setSelectedDate} />
              </div>
              <div className="column is-full">
                (colour indication shows the status on start date)
                <AreaSelect area={area} handleArea={handleArea} listAreas={listAreas} error={errors['location']} />
              </div>
              <div className="column is-full">
                {area && (
                  <>
                    <FloorPlanSwitcher
                      area={area}
                      bookings={allBookings}
                      handleAreaClick={handleAreaClick}
                      selectedArea={area}
                      user={user}
                      currentDate={selectedDate}
                    />
                  </>
                )}
                {deskSelectionError && (
                  <p style={{ color: 'red' }}>Please select a desk</p>
                )}
              </div>
              <div className="column is-full">
                <FrequencySelect frequency={frequency} handleFrequency={handleFrequency} />
              </div>
              <div className="column is-2">
                <DurationSelect duration={duration} setDuration={setDuration} />
              </div>
              <div className="column is-full">
                <label className="label">Calculated End Date:</label> {endDate}
              </div>
            </div>
            <div>
              <Section>
                <Form.Field kind="group">
                  <SearchInput value={inputText} onChange={setInputText} errors={errors} />
                  <Form.Control fullwidth>
                    <Form.Label htmlFor={`comment`}>Comment (optional)</Form.Label>
                    <Form.Input
                      id={`comment`}
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      placeholder="Enter an optional comment"
                    />
                  </Form.Control>
                </Form.Field>
                <div>
                  <Button type="submit" size="medium" align="center" color="warning">Submit</Button>
                </div>
              </Section>
            </div>
          </form>
        </Section>
        <Section>
          {showErrorModal && (
            <ErrorModal
              title={bookingErrorTitle}
              errors={bookingErrors}
              onClose={handleErrorModalClose}
              onContinue={() => handleErrorModalClose(true)}
              onReturn={handleErrorModalReturn}
            />
          )}
        </Section>
      </>
    </>
  );
});

BookingForm.displayName = 'BookingForm';

export default BookingForm;
