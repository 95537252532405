import React, { useContext, useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { TokenContext } from './TokenContext';

export const ApiTokenProvider = ({ children }) => {
    const { instance, inProgress, accounts } = useMsal();
    const [token, setToken] = useState(null);

    const acquireToken = async () => {
        if (inProgress === "none" && accounts.length > 0) {
            try {
                const response = await instance.acquireTokenSilent({
                    scopes: ["api://7178abff-411d-4c23-bdba-806af453c151/reservation/Reservation.All"],
                    account: accounts[0]
                });
                setToken(response.accessToken);
            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    await instance.acquireTokenRedirect({
                        scopes: ["api://7178abff-411d-4c23-bdba-806af453c151/reservation/Reservation.All"],
                        account: accounts[0]
                    });
                } else {
                    console.error("Error acquiring token:", error);
                }
            }
        }
    };

    useEffect(() => {
        acquireToken();
    }, [instance, inProgress, accounts]);

    return (
        <TokenContext.Provider value={{ token, acquireToken }}>
            {children}
        </TokenContext.Provider>
    );
};

export const useToken = () => useContext(TokenContext);
