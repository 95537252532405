import React from 'react';
import logo from '../images/beyond-logo.png';
import { useNavigate } from 'react-router-dom';
import { useMsal, useAccount } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";



const LoginComponent = ({ onLogin }) => {

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const navigate = useNavigate();

  const handleLogin = async () => {
    event.preventDefault(); // Prevents the default form submission behavior
    if (!account) {
      try {

        await instance.loginRedirect(loginRequest)
        // Once the loginRequest is successful, get the updated account
        const currentAccounts = instance.getAllAccounts();
        if (currentAccounts && currentAccounts.length > 0) {
          onLogin(currentAccounts[0].username);
        }
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          console.error(">>>>>>>>>>>>>>>> InteractionRequiredAuthError");
          // Fallback to redirect login if popup login fails
          instance.loginRedirect(loginRequest);
        } else {
          console.error("Authentication failed:", error);
        }
      }
    } else {
      console.info("Login successful");
      // Navigate to /app if already logged in
      navigate('/app');
    }
  };

  const title = "Desk reservation @ Beyond Now"


  return (
    <div className="container">
      <div className="columns is-vcentered">
        <div className="column is-5">
          <img src={logo} alt="Logo" style={{ marginBottom: '1rem' }} />
          <h2 style={{ marginBottom: '1rem' }}>{title}</h2>
          <button className="button is-link" onClick={handleLogin} style={{ marginTop: '1rem' }}>
            Login
          </button>
        </div>
      </div>
    </div>
  );

};

export default LoginComponent;
