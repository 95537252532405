import React from "react";
import { useMsal } from "@azure/msal-react";

const Home = () => {

 const { accounts } = useMsal();
  const userAccount = accounts[0] || null;
  const userName = userAccount ? userAccount.name : "No User";

  return (
    <section className="section">
      <div className="container">
        <h1 className="title">Welcome, {userName}!</h1>
        <p className="subtitle">
          Welcome to our desk reservation system!
        </p>
      </div>
    </section>
  );
};

export default Home;