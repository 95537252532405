import React from 'react';

import deskConfig5OG_A from '../../services/floorplan/og5/deskConfig5OG_A';
import deskConfig5OG_B from '../../services/floorplan/og5/deskConfig5OG_B';
import deskConfig5OG_C from '../../services/floorplan/og5/deskConfig5OG_C';
import deskConfig6OG_A from '../../services/floorplan/og6/deskConfig6OG_A';
import deskConfig6OG_B from '../../services/floorplan/og6/deskConfig6OG_B';
import deskConfig6OG_C from '../../services/floorplan/og6/deskConfig6OG_C';
import FloorplanBase from '../../services/floorplan/FloorPlanBase';

const FloorPlanSwitcher = ({ area, bookings, handleAreaClick, selectedArea, user, currentDate }) => {
    switch (area) {
        case '5OG_A':
            return (
                <FloorplanBase
                    imgSrc="/images/floorplan_05_A.jpg"
                    deskConfig={deskConfig5OG_A}
                    bookings={bookings}
                    handleAreaClick={handleAreaClick}
                    selectedArea={selectedArea}
                    user={user}
                    date={currentDate}
                />
            );
        case '5OG_B':
            return (
                <FloorplanBase
                    imgSrc="/images/floorplan_05_B.jpg"
                    deskConfig={deskConfig5OG_B}
                    bookings={bookings}
                    handleAreaClick={handleAreaClick}
                    selectedArea={selectedArea}
                    user={user}
                    date={currentDate}
                />
            );
        case '5OG_C':
            return (
                <FloorplanBase
                    imgSrc="/images/floorplan_05_C.jpg"
                    deskConfig={deskConfig5OG_C}
                    bookings={bookings}
                    handleAreaClick={handleAreaClick}
                    selectedArea={selectedArea}
                    user={user}
                    date={currentDate}
                />
            );

        case '6OG_A':
            return (
                <FloorplanBase
                    imgSrc="/images/floorplan_06_A.jpg"
                    deskConfig={deskConfig6OG_A}
                    bookings={bookings}
                    handleAreaClick={handleAreaClick}
                    selectedArea={selectedArea}
                    user={user}
                    date={currentDate}
                />
            );
        case '6OG_B':
            return (
                <FloorplanBase
                    imgSrc="/images/floorplan_06_B.jpg"
                    deskConfig={deskConfig6OG_B}
                    bookings={bookings}
                    handleAreaClick={handleAreaClick}
                    selectedArea={selectedArea}
                    user={user}
                    date={currentDate}
                />
            );
        case '6OG_C':
            return (
                <FloorplanBase
                    imgSrc="/images/floorplan_06_C.jpg"
                    deskConfig={deskConfig6OG_C}
                    bookings={bookings}
                    handleAreaClick={handleAreaClick}
                    selectedArea={selectedArea}
                    user={user}
                    date={currentDate}
                />
            );
        default:
            return null;
    }
};

export default FloorPlanSwitcher;
