import React, { useMemo, useRef, useState, useEffect } from 'react';
import deskConfig5OG from './deskConfig5OG';
import deskConfig6OG from './deskConfig6OG';
import { formatDateISO } from '../../utils/utils';
import useTooltipAdjustment from '../../hooks/useTooltipAdjustment';

const FloorPlan = ({ selectedFloor, bookings, handleAreaClick, selectedArea, user, date }) => {
    const containerRef = useRef(null);
    const imgRef = useRef(null);
    const [scale, setScale] = useState({ x: 1, y: 1 });
    const selectedDate = date;

    useTooltipAdjustment();

    useEffect(() => {
        const handleResize = () => {
            if (imgRef.current) {
                const img = imgRef.current;
                setScale({
                    x: img.clientWidth / img.naturalWidth,
                    y: img.clientHeight / img.naturalHeight
                });
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, [selectedFloor]);

    const handleImageLoad = () => {
        if (imgRef.current) {
            const img = imgRef.current;
            setScale({
                x: img.clientWidth / img.naturalWidth,
                y: img.clientHeight / img.naturalHeight
            });
        }
    };

    const tooltipText = (booking, isBooked, desk) => {
        return isBooked ? desk.name + `: Booked by ${booking.username}` : desk.name + '-> Desk available: ';
    };

    const deskConfig = selectedFloor === '6' ? deskConfig6OG : deskConfig5OG;

    const FloorPlanComponent = useMemo(() => {
        const deskBookings = new Map(bookings.filter(booking => booking.id)
            .filter(booking => booking.date == formatDateISO(selectedDate)) // Use `selectedDate` here
            .map(booking => [String(booking.deskid), booking]));

        return (
            <div ref={containerRef} className="floor-plan-container">
                <img
                    ref={imgRef}
                    src={selectedFloor === '6' ? "/images/floorplan_06.jpg" : "/images/floorplan_05.jpg"}
                    alt={`Floor Plan ${selectedFloor}OG`}
                    className="floor-plan-image"
                    onLoad={handleImageLoad}
                />
                <div className="areas">
                    {deskConfig.map((deskConfig) => {
                        const [x, y, width, height] = deskConfig.coords.split(',').map(Number);
                        const booking = deskBookings.get(String(deskConfig.id));
                        const isBooked = !!booking;
                        const isOwnBooking = booking && booking.username === user;

                        let areaClass = isBooked ? 'booked' : 'free';
                        if (isBooked) {
                            if (booking.isAdmin === 0 && isOwnBooking) {
                                areaClass += ' own';
                            } else if (booking.isAdmin === 1) {
                                areaClass += ' admin';
                            }
                        }

                        return (
                            <div
                                key={deskConfig.id}
                                className={`area ${areaClass} ${selectedArea === deskConfig.id ? 'selected' : ''}`}
                                id={deskConfig.id}
                                style={{
                                    top: `${y * scale.y}px`,
                                    left: `${x * scale.x}px`,
                                    width: `${width * scale.x}px`,
                                    height: `${height * scale.y}px`,
                                    position: 'absolute',
                                    boxSizing: 'border-box'
                                }}
                                onClick={() => handleAreaClick(deskConfig)}
                                data-tooltip={tooltipText(booking, isBooked, deskConfig)}
                            >
                                {deskConfig.name}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }, [bookings, selectedArea, selectedFloor, scale, handleAreaClick, user, selectedDate]);

    return FloorPlanComponent;
};

export default FloorPlan;
