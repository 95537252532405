import React from 'react';

const RepeatCycleSelect = ({ frequency, handleFrequency }) => {
  return (
    <div className="field">
      <label className="label">Frequency</label>
      <div className="control is-normal">
        <div className="select">
          <select value={frequency} onChange={handleFrequency}>
            <option value="1">Every week</option>
            <option value="2">Every 2 weeks</option>
            <option value="3">Every 3 weeks</option>
            <option value="4">Every 4 weeks</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default RepeatCycleSelect;
