import React from "react";
import { Navbar } from "react-bulma-components";
import { useMsal } from "@azure/msal-react";

const UsersComponent = () => {
  const { instance, accounts } = useMsal();


  const handleLogout = () => {
    // Perform logout using MSAL instance
    instance.logoutRedirect(); // or instance.logoutPopup() based on your app's flow
  };

  // Get the current user's account details
  const userAccount = accounts[0] || null;
  const userName = userAccount ? userAccount.username : "No User";


  return (
    <>
      <Navbar>
        <Navbar.Item> User: {userName} </Navbar.Item>
        <Navbar.Item onClick={handleLogout} className="navbar-item">
          Logout
        </Navbar.Item>
      </Navbar>
    </>
  );
};

export default UsersComponent;
