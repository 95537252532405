import { Client } from "@microsoft/microsoft-graph-client";

const getAuthenticatedGraphClient = (accessToken) => {
    const authProvider = {
        getAccessToken: () => Promise.resolve(accessToken),
    };

    return Client.initWithMiddleware({ authProvider });
};

const searchUsers = async (searchQuery, tokenProvider) => {
    let accessToken = tokenProvider.token;
    let client = getAuthenticatedGraphClient(accessToken);

    try {
        const result = await client.api('/users')
            .filter(`(startsWith(displayName,'${searchQuery}') or startsWith(mail,'${searchQuery}'))`)
            .select('displayName,mail,userPrincipalName')
            .get();

        return result.value.filter(user => user.mail && !user.mail.endsWith('@bearingpoint.com'));

    } catch (error) {
        if (error.statusCode === 401) {
            console.warn("Token expired, attempting to refresh...");
            try {
                // Refresh the token
                accessToken = await tokenProvider.refreshToken();
                client = getAuthenticatedGraphClient(accessToken);

                // Retry the request with the new token
                const result = await client.api('/users')
                    .filter(`(startsWith(displayName,'${searchQuery}') or startsWith(mail,'${searchQuery}'))`)
                    .select('displayName,mail,userPrincipalName')
                    .get();

                return result.value.filter(user => user.mail && !user.mail.endsWith('@bearingpoint.com'));
            } catch (refreshError) {
                console.error("Error refreshing the token or retrying the request:", refreshError);
            }
        } else {
            console.error("Error searching users:", error);
        }
    }
};

export default searchUsers;
