const deskConfig5OG_C = [
    { id: '133', name: 'C: 1', coords: '600,1655,100,50' },
    { id: '134', name: 'C: 2', coords: '700,1655,100,50' },
    { id: '135', name: 'C: 3', coords: '600,1705,100,50' },
    { id: '136', name: 'C: 4', coords: '700,1705,100,50' },
    { id: '137', name: 'C: 5', coords: '595,980,100,50' },
    { id: '138', name: 'C: 6', coords: '695,980,100,50' },
    { id: '139', name: 'C: 7', coords: '595,1030,100,50' },
    { id: '140', name: 'C: 8', coords: '695,1030,100,50' },
    { id: '141', name: 'C: 9', coords: '570,480,100,50' },
    { id: '142', name: 'C: 10', coords: '670,480,100,50' },
    { id: '143', name: 'C: 11', coords: '570,530,100,50' },
    { id: '144', name: 'C: 12', coords: '670,530,100,50' },
    { id: '145', name: 'C: 13', coords: '440,60,50,100' },
    { id: '146', name: 'C: 14', coords: '440,160,50,100' },
    { id: '147', name: 'C: 15', coords: '490,60,50,100' },
    { id: '148', name: 'C: 16', coords: '490,160,50,100' },
    { id: '149', name: 'C: 17', coords: '30,390,100,50' },
    { id: '150', name: 'C: 18', coords: '130,390,100,50' },
    { id: '151', name: 'C: 19', coords: '60,450,100,50' },
    { id: '152', name: 'C: 20', coords: '165,450,100,50' }
]

export default deskConfig5OG_C