// NavLink.js
import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';

const NavLink = ({ to, children, ...props }) => {
    return (
        <RouterNavLink
            to={to}
            className={({ isActive }) => isActive ? 'navbar-item is-active' : 'navbar-item'}
            {...props}
        >
            {children}
        </RouterNavLink>
    );
};

export default NavLink;
