import LoginComponent from '../components/login.component';

import React from 'react';


const LoginPage = ({ onLogin }) => {


  const handleLogin = (email) => {
    const isValid = onLogin(email);
    if (isValid) {
      console.error("Valid login")
    } else {
    console.error("Invalid login");
    }
  }

  return <LoginComponent onLogin={handleLogin} />; // Pass handleLogin instead of onLogin
};

export default LoginPage;
