const deskConfig5OG_A = [
    { id: '89', name: 'A: 1', coords: '98,40,50,100' },
    { id: '90', name: 'A: 2', coords: '98,140,50,100' },
    { id: '91', name: 'A: 3', coords: '148,40,50,100' },
    { id: '92', name: 'A: 4', coords: '148,140,50,100' },
    { id: '93', name: 'A: 5', coords: '345,35,50,100' },
    { id: '94', name: 'A: 6', coords: '345,135,50,100' },
    { id: '95', name: 'A: 7', coords: '395,35,50,100' },
    { id: '96', name: 'A: 8', coords: '395,135,50,100' },
    { id: '97', name: 'A: 9', coords: '600,30,50,100' },
    { id: '98', name: 'A: 10', coords: '600,130,50,100' },
    { id: '99', name: 'A: 11', coords: '650,30,50,100' },
    { id: '100', name: 'A: 12', coords: '650,130,50,100' },
    { id: '101', name: 'A: 13', coords: '900,70,55,100' },
    { id: '102', name: 'A: 14', coords: '950,70,55,100' },
    { id: '103', name: 'A: 15', coords: '915,580,50,100' },
    { id: '104', name: 'A: 16', coords: '915,680,50,100' },
    { id: '105', name: 'A: 17', coords: '965,580,50,100' },
    { id: '106', name: 'A: 18', coords: '965,680,50,100' },
    { id: '107', name: 'A: 19', coords: '630,600,50,100' },
    { id: '108', name: 'A: 20', coords: '630,700,50,100' },
    { id: '109', name: 'A: 21', coords: '680,600,50,100' },
    { id: '110', name: 'A: 22', coords: '680,700,50,100' }
]

export default deskConfig5OG_A