import { useEffect } from 'react';

const useTooltipAdjustment = () => {
    useEffect(() => {
        const adjustTooltipPosition = (element) => {
            const tooltipText = element.getAttribute('data-tooltip');
            if (!tooltipText) return;

            // Remove any existing tooltip for this element to avoid duplicates
            let existingTooltip = element.querySelector('.tooltip-popup');
            if (existingTooltip) {
                existingTooltip.remove();
            }

            // Create a new tooltip element
            const tooltip = document.createElement('div');
            tooltip.className = 'tooltip-popup';
            tooltip.innerText = tooltipText;
            document.body.appendChild(tooltip);

            // Measure the tooltip dimensions
            const tooltipRect = tooltip.getBoundingClientRect();
            const elementRect = element.getBoundingClientRect();
            const viewportWidth = window.innerWidth;

            let tooltipLeft = elementRect.left + elementRect.width / 2 - tooltipRect.width / 2;
            let tooltipTop = elementRect.top - tooltipRect.height - 10; // Position above the element

            // Adjust if the tooltip overflows to the left
            if (tooltipLeft < 0) {
                tooltipLeft = 10; // 10px padding from the left edge of the viewport
            }

            // Adjust if the tooltip overflows to the right
            if (tooltipLeft + tooltipRect.width > viewportWidth) {
                tooltipLeft = viewportWidth - tooltipRect.width - 10; // 10px padding from the right edge
            }

            // Adjust if the tooltip overflows above the top
            if (tooltipTop < 0) {
                tooltipTop = elementRect.bottom + 10; // Position below the element if it overflows above
            }

            // Apply the calculated position
            tooltip.style.position = 'absolute';
            tooltip.style.left = `${tooltipLeft}px`;
            tooltip.style.top = `${tooltipTop}px`;
            tooltip.style.visibility = 'visible';

            // Attach tooltip to the element on hover
            element.addEventListener('mouseenter', () => {
                tooltip.style.visibility = 'visible';
            });

            // Hide the tooltip on mouse leave
            element.addEventListener('mouseleave', () => {
                tooltip.style.visibility = 'hidden';
            });

            // Cleanup on component unmount
            return () => {
                tooltip.remove();
            };
        };

        const elements = document.querySelectorAll('.tooltip[data-tooltip]');
        elements.forEach((element) => {
            adjustTooltipPosition(element);
        });

        // Cleanup event listeners on component unmount
        return () => {
            elements.forEach((element) => {
                element.removeEventListener('mouseenter', () => adjustTooltipPosition(element));
            });
        };
    }, []);
};

export default useTooltipAdjustment;
