import { useState } from 'react';

const useRepeatCycle = () => {
  const [frequency, setFrequency] = useState(1);

  const handleFrequency = (event) => {
    const newFrequency = event.target.value;
    setFrequency(newFrequency);
  };

  return { frequency, handleFrequency };
};

export default useRepeatCycle;
