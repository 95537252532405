const ErrorModal = ({ title, errors, onClose, onContinue, onReturn }) => {
  if (!errors || errors.length === 0) return null;

  const displayErrors = errors.length > 10
    ? <p>{errors.length} bookings are overlapping with already existing bookings.</p>
    : <ul>
        {errors.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>;

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Booking Errors: {title}</p>
          <button className="delete" aria-label="close" onClick={() => onClose(false)}></button>
        </header>
        <section className="modal-card-body">
          {displayErrors}
          <p></p>
          <p></p>
          <p>Conflicts will not be overwritten. If there are any unconflicting remaining bookings left, they can be booked now.</p>
          <p>Would you like to return to the page (return), continue with non-conflicting bookings (continue), or abort the proccess (abort)?</p>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-info" onClick={() => onReturn()}>Return</button>
          <button className="button is-success" onClick={() => onContinue()}>Continue</button>
          <button className="button is-danger" onClick={() => onClose(false)}>Abort</button>
        </footer>
      </div>
    </div>
  );
};

export default ErrorModal;
