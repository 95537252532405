const deskConfig6OG_B = [
    { id: '31', name: 'B: 3', coords: '35,80,25,35' },
    { id: '32', name: 'B: 4', coords: '35,115,25,35' },
    { id: '33', name: 'B: 5', coords: '90,75,25,35' },
    { id: '34', name: 'B: 6', coords: '90,110,25,35' },
    { id: '35', name: 'B: 7', coords: '115,75,25,35' },
    { id: '36', name: 'B: 8', coords: '115,110,25,35' },
    { id: '37', name: 'B: 9', coords: '200,170,25,35' },
    { id: '38', name: 'B: 10', coords: '200,205,25,35' },
    { id: '39', name: 'B: 11', coords: '225,170,25,35' },
    { id: '40', name: 'B: 12', coords: '225,205,25,35' },
    { id: '41', name: 'B: 13', coords: '280,175,25,35' },
    { id: '42', name: 'B: 14', coords: '280,210,25,35' },
    { id: '43', name: 'B: 15', coords: '305,175,25,35' },
    { id: '44', name: 'B: 16', coords: '305,210,25,35' },
    { id: '45', name: 'B: 17', coords: '285,100,30,15' },
    { id: '46', name: 'B: 18', coords: '315,100,30,15' },
    { id: '47', name: 'B: 19', coords: '270,120,30,15' },
    { id: '48', name: 'B: 20', coords: '310,120,30,15' },
    { id: '49', name: 'B: 21', coords: '280,20,35,25' },
    { id: '50', name: 'B: 22', coords: '315,20,35,25' },
    { id: '51', name: 'B: 23', coords: '280,45,35,25' },
    { id: '52', name: 'B: 24', coords: '315,45,35,25' }
]

export default deskConfig6OG_B