const deskConfig6OG = [
    { id: '1', name: 'A: 1', coords: '15,710,25,35' },
    { id: '2', name: 'A: 2', coords: '15,745,25,35' },
    { id: '3', name: 'A: 3', coords: '45,710,25,35' },
    { id: '4', name: 'A: 4', coords: '45,745,25,35' },
    { id: '5', name: 'A: 5', coords: '100,700,25,35' },
    { id: '6', name: 'A: 6', coords: '100,735,25,35' },
    { id: '7', name: 'A: 7', coords: '125,700,25,35' },
    { id: '8', name: 'A: 8', coords: '125,735,25,35' },
    { id: '9', name: 'A: 9', coords: '270,710,25,35' },
    { id: '10', name: 'A: 10', coords: '270,745,25,35' },
    { id: '11', name: 'A: 11', coords: '295,710,25,35' },
    { id: '12', name: 'A: 12', coords: '295,745,25,35' },
    { id: '13', name: 'A: 13', coords: '350,710,25,35' },
    { id: '14', name: 'A: 14', coords: '350,745,25,35' },
    { id: '15', name: 'A: 15', coords: '375,710,25,35' },
    { id: '16', name: 'A: 16', coords: '375,745,25,35' },
    { id: '17', name: 'A: 17', coords: '350,520,25,35' },
    { id: '18', name: 'A: 18', coords: '350,555,25,35' },
    { id: '19', name: 'A: 19', coords: '375,520,25,35' },
    { id: '20', name: 'A: 20', coords: '375,555,25,35' },
    { id: '21', name: 'A: 21', coords: '190,520,25,35' },
    { id: '22', name: 'A: 22', coords: '190,555,25,35' },
    { id: '23', name: 'A: 23', coords: '215,520,25,35' },
    { id: '24', name: 'A: 24', coords: '215,555,25,35' },
    { id: '25', name: 'A: 25', coords: '115,520,25,35' },
    { id: '26', name: 'A: 26', coords: '115,555,25,35' },
    { id: '27', name: 'A: 27', coords: '140,520,25,35' },
    { id: '28', name: 'A: 28', coords: '140,555,25,35' },
    { id: '31', name: 'B: 3', coords: '755,705,25,35' },
    { id: '32', name: 'B: 4', coords: '755,740,25,35' },
    { id: '33', name: 'B: 5', coords: '800,695,25,35' },
    { id: '34', name: 'B: 6', coords: '800,730,25,35' },
    { id: '35', name: 'B: 7', coords: '825,695,25,35' },
    { id: '36', name: 'B: 8', coords: '825,730,25,35' },
    { id: '37', name: 'B: 9', coords: '930,800,25,35' },
    { id: '38', name: 'B: 10', coords: '930,835,25,35' },
    { id: '39', name: 'B: 11', coords: '955,800,25,35' },
    { id: '40', name: 'B: 12', coords: '955,835,25,35' },
    { id: '41', name: 'B: 13', coords: '1000,800,25,35' },
    { id: '42', name: 'B: 14', coords: '1000,835,25,35' },
    { id: '43', name: 'B: 15', coords: '1025,800,25,35' },
    { id: '44', name: 'B: 16', coords: '1025,835,25,35' },
    { id: '45', name: 'B: 17', coords: '990,730,35,25' },
    { id: '46', name: 'B: 18', coords: '1025,730,35,25' },
    { id: '47', name: 'B: 19', coords: '990,755,35,25' },
    { id: '48', name: 'B: 20', coords: '1025,755,35,25' },
    { id: '49', name: 'B: 21', coords: '1000,640,35,25' },
    { id: '50', name: 'B: 22', coords: '1035,640,35,25' },
    { id: '51', name: 'B: 23', coords: '1000,665,35,25' },
    { id: '52', name: 'B: 24', coords: '1035,665,35,25' },
    { id: '53', name: 'C: 1', coords: '1000,545,35,25' },
    { id: '54', name: 'C: 2', coords: '1035,545,35,25' },
    { id: '55', name: 'C: 3', coords: '1000,570,35,25' },
    { id: '56', name: 'C: 4', coords: '1035,570,35,25' },
    { id: '57', name: 'C: 5', coords: '1000,470,35,25' },
    { id: '58', name: 'C: 6', coords: '1035,470,35,25' },
    { id: '59', name: 'C: 7', coords: '1000,495,35,25' },
    { id: '60', name: 'C: 8', coords: '1035,495,35,25' },
    { id: '61', name: 'C: 9', coords: '990,285,35,25' },
    { id: '62', name: 'C: 10', coords: '1025,285,35,25' },
    { id: '63', name: 'C: 11', coords: '990,310,35,25' },
    { id: '64', name: 'C: 12', coords: '1025,310,35,25' },
    { id: '65', name: 'C: 13', coords: '1000,210,40,20' },
    { id: '66', name: 'C: 14', coords: '1030,210,40,20' },
    { id: '67', name: 'C: 15', coords: '990,135,35,25' },
    { id: '68', name: 'C: 16', coords: '1025,135,35,25' },
    { id: '69', name: 'C: 17', coords: '990,160,35,25' },
    { id: '70', name: 'C: 18', coords: '1025,160,35,25' },
    { id: '71', name: 'C: 19', coords: '1000,60,35,25' },
    { id: '72', name: 'C: 20', coords: '1035,60,35,25' },
    { id: '73', name: 'C: 21', coords: '1000,85,35,25' },
    { id: '74', name: 'C: 22', coords: '1035,85,35,25' },
    { id: '75', name: 'C: 23', coords: '820,135,35,25' },
    { id: '76', name: 'C: 24', coords: '855,135,35,25' },
    { id: '77', name: 'C: 25', coords: '820,160,35,25' },
    { id: '78', name: 'C: 26', coords: '855,160,35,25' },
    { id: '79', name: 'C: 27', coords: '810,210,35,25' },
    { id: '80', name: 'C: 28', coords: '845,210,35,25' },
    { id: '81', name: 'C: 29', coords: '810,235,35,25' },
    { id: '82', name: 'C: 30', coords: '845,235,35,25' },
    { id: '83', name: 'C: 31', coords: '820,280,30,32' },
    { id: '84', name: 'C: 32', coords: '850,280,30,32' }
]

export default deskConfig6OG;