import { useState } from 'react';

const useAreaSelection = (areas) => {

  const [area, setArea] = useState(''); // Store the selected room's ID
  const [areaTitle, setAreaTitle] = useState('');


  const handleArea = (event) => {
    const selectedAreaId = event.target.value;
    const foundArea = areas.find((a) => a.id === selectedAreaId);

    if (foundArea) {
      setArea(selectedAreaId);
      setAreaTitle(foundArea.title);
    }
  };

  const handleAreaViaId = (area) => {
    const selectedAreaId = area.id;
    const foundArea = areas.find((a) => a.id === selectedAreaId);

    if (foundArea) {
      setArea(selectedAreaId);
      setAreaTitle(foundArea.title);
    }
  };


  const listAreas = () => {
    return areas.map((a) => (
      <option key={a.id} value={a.id}>Floor {a.floor}: {a.title}</option>
    ));
  };

  return { area, areaTitle, handleArea, listAreas, handleAreaViaId };
};

export default useAreaSelection;
