const useDaySelection = (checkedDays, setCheckedDays) => {


 const handleDayToggle = (dayIndex) => {
   setCheckedDays((currentCheckedDays) => {
     const updatedDays = [...currentCheckedDays];
     updatedDays[dayIndex] = !updatedDays[dayIndex]; // Toggle the selected day
     return updatedDays;
   });
 };

  return { checkedDays, handleDayToggle };
};

export default useDaySelection;
