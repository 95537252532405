import React from 'react';

const DurationSelect = ({ duration, setDuration }) => {
    const handleDurationChange = (event) => {
        setDuration(Number(event.target.value));
    };

    return (
        <div className="field">
            <label className="label">Number of weeks to be booked</label>
            <div className="control">
                <input
                    className="input"
                    type="number"
                    min="1"
                    max="26" // Limit the maximum value to 26 weeks
                    value={duration}
                    onChange={handleDurationChange}
                />
            </div>
        </div>
    );
};


export default DurationSelect;
