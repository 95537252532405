const deskConfig6OG_A = [
    { id: '1', name: 'A: 1', coords: '15,190,25,35' },
    { id: '2', name: 'A: 2', coords: '15,225,25,35' },
    { id: '3', name: 'A: 3', coords: '40,190,25,35' },
    { id: '4', name: 'A: 4', coords: '40,225,25,35' },
    { id: '5', name: 'A: 5', coords: '85,180,25,35' },
    { id: '6', name: 'A: 6', coords: '85,215,25,35' },
    { id: '7', name: 'A: 7', coords: '110,180,25,35' },
    { id: '8', name: 'A: 8', coords: '110,215,25,35' },
    { id: '9', name: 'A: 9', coords: '260,190,25,35' },
    { id: '10', name: 'A: 10', coords: '260,225,25,35' },
    { id: '11', name: 'A: 11', coords: '285,190,25,35' },
    { id: '12', name: 'A: 12', coords: '285,225,25,35' },
    { id: '13', name: 'A: 13', coords: '345,190,25,35' },
    { id: '14', name: 'A: 14', coords: '345,225,25,35' },
    { id: '15', name: 'A: 15', coords: '370,190,25,35' },
    { id: '16', name: 'A: 16', coords: '370,225,25,35' },
    { id: '17', name: 'A: 17', coords: '350,10,25,35' },
    { id: '18', name: 'A: 18', coords: '350,45,25,35' },
    { id: '19', name: 'A: 19', coords: '375,10,25,35' },
    { id: '20', name: 'A: 20', coords: '375,45,25,35' },
    { id: '21', name: 'A: 21', coords: '180,10,25,35' },
    { id: '22', name: 'A: 22', coords: '180,45,25,35' },
    { id: '23', name: 'A: 23', coords: '205,10,25,35' },
    { id: '24', name: 'A: 24', coords: '205,45,25,35' },
    { id: '25', name: 'A: 25', coords: '110,10,25,35' },
    { id: '26', name: 'A: 26', coords: '110,45,25,35' },
    { id: '27', name: 'A: 27', coords: '135,10,25,35' },
    { id: '28', name: 'A: 28', coords: '135,45,25,35' }
]

export default deskConfig6OG_A