import React, { useState, useEffect } from "react";
import moment from "moment";
import { Table, Section, Button } from "react-bulma-components";
import Calendar from "react-calendar";
import useAxiosWithToken from '../hooks/axiosTokenHook';
import { formatDateUI, formatDateISO } from "../utils/utils";

const BookingOverviewComponent = () => {
  const axios = useAxiosWithToken();

  const [bookings, setBookings] = useState([]);

  //Loading progress bar state
  const [isLoading, setIsLoading] = useState(false);

  const [actualDate, onChange] = useState(new Date());

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`bookings?date=${formatDateISO(actualDate)}`)
      .then((response) => {
        if (response.data) setBookings(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

  }, [actualDate, axios]);

  const DataTable = () => {
    return bookings
      .filter((b) => b.id > 0)
      .map((res) => {
        let dateformat = formatDateUI(moment(res.date));
        const rowClass = res.isAdmin === 1 ? "has-background-grey-lighter" : "";

        return (
          // Apply the rowClass to this <tr> to conditionally style admin bookings
          <tr key={res.id} className={rowClass}>
            <td data-label="Date">{dateformat}</td>
            <td data-label="User" className="long-text">{res.username}</td>
            <td data-label="Floor">{res.floor}</td>
            <td data-label="Area">{res.areatitle}</td>
            <td data-label="Desk">{res.desktitle}</td>
            <td data-label="Comment by Admin" className={res.comment ? "" : "empty-comment"}>
              {res.comment || " "}
            </td>
          </tr>
        );
      });
  };

  return (
    <>
      {isLoading && <progress className="progress is-large is-info" max="100">Loading...</progress>}
      <>
        <Section>
          <br />
          <p>This overview shows all booked desks for the selected day!</p>
          <br />
        </Section>
        <Section>
          <div className="calendar-container" style={{ paddingTop: '20px' }}>
            <Calendar onChange={onChange} value={actualDate} minDate={new Date()} />
          </div>
        </Section>
        <Section>
          <div className="columns">
            {/* Third Column (On the Left) */}
            <div className="column is-9">
              <div className="buttons are-small" style={{
                justifyContent: "flex-end", marginTop: '50px'
              }}>
                <Button style={{ backgroundColor: '#d3d3d3', color: 'black' }}>Admin booking</Button>
              </div>
            </div>
          </div>
        </Section>

        <Section className="table-wrapper">
          <p>Booked shared desks.</p>
          <Table striped bordered hoverable className="is-fullwidth">
            <thead>
              <tr className="is-selected">
                <th>Date</th>
                <th>User</th>
                <th>Floor</th>
                <th>Area</th>
                <th>Desk</th>
                <th>Comment by Admin</th>
              </tr>
            </thead>
            <tbody>{DataTable()}</tbody>
          </Table>
        </Section>
      </>
    </>
  );
};

export default BookingOverviewComponent;
