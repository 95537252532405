// UserContext.js
import React, { createContext, useContext, useState } from 'react';

const UserContext = createContext(); 
export function UserProvider({ children }) {
  const [isAdmin, setIsAdmin] = useState(false);
  const value = { isAdmin, setIsAdmin };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export function useUser() {
  return useContext(UserContext);
}
