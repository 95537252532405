
import React from 'react';

const ErrorMessages = ({ errors }) => {
  if (!errors || errors.length === 0) return null;

  return (
    <div className="notification is-danger">
      <p><b>Errors during booking:</b></p>
      {errors.map((error, index) => <p key={index}>{error}</p>)}
    </div>
  );
};

export default ErrorMessages;