import React, { useState, useEffect } from 'react';
import { Form } from "react-bulma-components";
import searchUsers from './msGraphService';
import { useMsGraphToken } from "../contexts/MsGraphTokenProvider";

const SearchInput = ({ value, onChange, errors }) => {
    const [searchResults, setSearchResults] = useState([]);
    const [doSearch, setDoSearch] = useState();
    const tokenProvider = useMsGraphToken();

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (doSearch && value.length > 2 && tokenProvider.token) {
                searchUsers(value, tokenProvider)  
                    .then(results => setSearchResults(results))
                    .catch(error => console.error("Search error:", error));
            } else {
                setSearchResults([]);
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [value, tokenProvider]); 

    const handleResultClick = (selectedUser) => {
        onChange(selectedUser.mail);
        setSearchResults([]); // Clear search results after selection
        setDoSearch(false);
    };

    return (
        <Form.Control fullwidth>
            <Form.Label htmlFor={`nameOrEmail`}>Name or E-Mail</Form.Label>
            <Form.Input
                id={`nameOrEmail`}
                value={value}
                onChange={(e) => {
                    onChange(e.target.value);
                    setDoSearch(true);
                }}
                placeholder="Enter a Name or an E-Mail-Address"
            />
            {errors['email'] && <div style={{ color: 'red' }}>{errors['email']}</div>}
            {searchResults.length > 0 && (
                <div style={{ position: 'absolute', backgroundColor: 'white', border: '1px solid #DDD', borderRadius: '5px', zIndex: 1000 }}>
                    {searchResults.map((user, index) => (
                        <div
                            key={index}
                            onClick={() => handleResultClick(user)}
                            style={{ padding: '10px', cursor: 'pointer', borderBottom: '1px solid #DDD' }}
                            onMouseDown={(e) => e.preventDefault()} // Prevent input from losing focus
                        >
                            {user.displayName} ({user.mail})
                        </div>
                    ))}
                </div>
            )}
        </Form.Control>
    );
};

export default SearchInput;
