import { useEffect, useState } from 'react';
import { useUser } from '../contexts/UserContext';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";


function useMsGraphToken() {
    const { instance, inProgress, accounts } = useMsal();
    const [msGraphToken, setMsGraphToken] = useState(null);

    useEffect(() => {
        const acquireToken = async () => {
            if (inProgress === "none" && accounts.length > 0) {
                try {
                    const response = await instance.acquireTokenSilent({
                        scopes: ["User.Read", "Group.Read.All", "User.ReadBasic.All"],
                        account: accounts[0]
                    });
                    setMsGraphToken(response.accessToken);
                } catch (error) {
                    if (error instanceof InteractionRequiredAuthError) {
                        // Fallback to interactive method if silent acquisition fails
                        try {
                            const response = await instance.acquireTokenPopup({
                                scopes: ["User.Read", "GroupMember.Read.All"],
                                account: accounts[0]
                            });
                            setMsGraphToken(response.accessToken);
                        } catch (popupError) {
                            console.error("Error acquiring MS Graph token via popup:", popupError);
                        }
                    } else {
                        console.error("Error acquiring MS Graph token:", error);
                    }
                }
            }
        };

        acquireToken();
    }, [instance, inProgress, accounts]);

    return msGraphToken;
}

async function fetchUserGroup(msGraphToken, targetGroupName) {
    let endpoint = 'https://graph.microsoft.com/v1.0/me/memberOf';
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${msGraphToken}`);

    try {
        while (endpoint) {
            const response = await fetch(endpoint, { headers: headers });
            if (!response.ok) {
                throw new Error('Failed to fetch user groups');
            }

            const data = await response.json();

            // Check if the desired group is found
            const targetGroup = data.value.find(group => group.displayName === targetGroupName && group['@odata.type'] === '#microsoft.graph.group');
            if (targetGroup) {
                return true; // Group found, return true
            }

            // Check if there is more data (pagination)
            endpoint = data['@odata.nextLink'] || null;
        }

        return false;
    } catch (error) {
        console.error('Error fetching user groups from Microsoft Graph:', error);
        throw error;
    }
}



function AdminStatusSetter() {
    const msGraphToken = useMsGraphToken();
    const { setIsAdmin } = useUser();

    useEffect(() => {
        const setAdminStatus = async () => {
            try {
                const adminGroupFound = await fetchUserGroup(msGraphToken, "BN-ENG desk reservation Admins");
                setIsAdmin(adminGroupFound);
            } catch (error) {
                console.error('Error setting admin status:', error);
                setIsAdmin(false); // Set to false if there's an error
            }
        };

        if (msGraphToken) {
            setAdminStatus();
        }
    }, [msGraphToken, setIsAdmin]);

    return null; // This component doesn't need to render anything
}


export default AdminStatusSetter;
