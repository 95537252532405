import React, { useState } from "react";
import "bulma/css/bulma.min.css";
import { Navbar } from "react-bulma-components";
import UsersComponent from "./users.component";
import NavLink from "../utils/NavLink"; // Adjust the import path as necessary
import { useUser } from '../contexts/UserContext';
import logo from "../images/beyond-logo.png";

function Navigation() {
  const { isAdmin } = useUser();
  const [isActive, setIsActive] = useState(false); // State to manage the navbar burger

  // Toggle function for Navbar Burger
  const toggleNavbar = () => {
    setIsActive(!isActive);
  };

  return (
    <Navbar className="is-primary" role="navigation" aria-label="main navigation">
      <Navbar.Brand>
        <Navbar.Item renderAs="a" href="/app">
          <img src={logo} alt="Logo" />
        </Navbar.Item>
      </Navbar.Brand>
      <div className="navbar-end">
        <Navbar.Burger onClick={toggleNavbar} className={`navbar-burger ${isActive ? "is-active" : ""} is-hidden-desktop`} />
      </div>

      <Navbar.Menu className={isActive ? 'is-active' : ''}>
        <Navbar.Container>
          <NavLink to="/app/booking-list">My Bookings</NavLink>
          <NavLink to="/app/shareddesk">Book shared desk</NavLink>
          <NavLink to="/app/booking-overview">Overview</NavLink>
          <NavLink to="/app/booking-overview-advanced">Advanced Overview</NavLink> 
        </Navbar.Container>

        <Navbar.Container align="right">
          {isAdmin && (
            <>
              <NavLink to="/app/admin-booking">Admin Bookings</NavLink>
            </>
          )}
          <a href="https://teams.microsoft.com/l/channel/19%3A7adbc31c68a741f1802242d02fd01701%40thread.tacv2/SDRT-Feedback?groupId=5e0f30b6-91aa-4c28-a73f-261205ab36ce&tenantId=a00c0469-8b25-4b75-ba6c-e8aa467c9bbe" target="_blank" rel="noopener noreferrer" className="navbar-item button is-primary">Contact Us</a>
          <UsersComponent />
        </Navbar.Container>
      </Navbar.Menu>
    </Navbar>
  );
}

export default Navigation;
