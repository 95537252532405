import React from 'react';
import { Modal, Button } from 'react-bulma-components';
import moment from "moment";
import { formatDateUI } from '../utils/utils';

const DeleteConfirmationComponent = ({ showConfirmation, setShowConfirmation, onConfirmDelete, selectedBookings = [], bookings = [] }) => {

  const bookingItems = selectedBookings.map((bookingId) => {
    const booking = bookings.find((b) => b.id === bookingId);
    if (booking) {
      return (
        <li key={booking.id}>
          * {formatDateUI(moment(booking.date))} on floor {booking.floor}, area {booking.areatitle}, desk no {booking.desktitle}
        </li>
      );
    }
    return null; // Return null for undefined bookings
  });

  return (
    <Modal show={showConfirmation} onClose={() => setShowConfirmation(false)}>
      <Modal.Card>
        <Modal.Card.Header>
          Delete Confirmation
        </Modal.Card.Header>
        <Modal.Card.Body>
          {selectedBookings.length > 0 ? (
            <div>
              <p>Are you sure you want to delete the selected bookings?</p>
              <ul>
                {bookingItems}
              </ul>
            </div>
          ) : (
            <p>No bookings selected for deletion.</p>
          )}
        </Modal.Card.Body>
        {selectedBookings.length > 0 && (
          <Modal.Card.Footer>
            <Button onClick={() => onConfirmDelete()} color="danger">
              Confirm
            </Button>
            <Button onClick={() => setShowConfirmation(false)}>Cancel</Button>
          </Modal.Card.Footer>
        )}
      </Modal.Card>
    </Modal>
  );
};

export default DeleteConfirmationComponent;