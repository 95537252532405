import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext'; 

const ProtectedRoute = ({ children }) => {
    const { isAdmin } = useUser();

    if (!isAdmin) {
        // Redirect to the home page or login page
        return <Navigate to="/" replace />;
    }

    return children;
};

export default ProtectedRoute;
