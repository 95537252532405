const deskConfig5OG = [
    { id: '89', name: 'A: 1', coords: '1700,1650,50,100' },
    { id: '90', name: 'A: 2', coords: '1700,1750,50,100' },
    { id: '91', name: 'A: 3', coords: '1750,1650,50,100' },
    { id: '92', name: 'A: 4', coords: '1750,1750,50,100' },
    { id: '93', name: 'A: 5', coords: '1935,1670,50,100' },
    { id: '94', name: 'A: 6', coords: '1935,1770,50,100' },
    { id: '95', name: 'A: 7', coords: '1985,1670,50,100' },
    { id: '96', name: 'A: 8', coords: '1985,1770,50,100' },
    { id: '97', name: 'A: 9', coords: '2181,1635,50,100' },
    { id: '98', name: 'A: 10', coords: '2181,1735,50,100' },
    { id: '99', name: 'A: 11', coords: '2231,1635,50,100' },
    { id: '100', name: 'A: 12', coords: '2231,1735,50,100' },
    { id: '101', name: 'A: 13', coords: '2480,1675,55,100' },
    { id: '102', name: 'A: 14', coords: '2550,1680,55,100' },
    { id: '103', name: 'A: 15', coords: '2515,2190,50,100' },
    { id: '104', name: 'A: 16', coords: '2515,2290,50,100' },
    { id: '105', name: 'A: 17', coords: '2565,2190,50,100' },
    { id: '106', name: 'A: 18', coords: '2565,2290,50,100' },
    { id: '107', name: 'A: 19', coords: '2220,2210,50,100' },
    { id: '108', name: 'A: 20', coords: '2220,2310,50,100' },
    { id: '109', name: 'A: 21', coords: '2270,2210,50,100' },
    { id: '110', name: 'A: 22', coords: '2270,2310,50,100' },
    { id: '111', name: 'B: 1', coords: '3580,2210,50,100' },
    { id: '112', name: 'B: 2', coords: '3580,2310,50,100' },
    { id: '113', name: 'B: 3', coords: '3630,2210,50,100' },
    { id: '114', name: 'B: 4', coords: '3630,2310,50,100' },
    { id: '115', name: 'B: 5', coords: '3805,2180,50,100' },
    { id: '116', name: 'B: 6', coords: '3805,2280,50,100' },
    { id: '117', name: 'B: 7', coords: '3855,2180,50,100' },
    { id: '118', name: 'B: 8', coords: '3855,2280,50,100' },
    { id: '119', name: 'B: 9', coords: '3895,2500,50,100' },
    { id: '120', name: 'B: 10', coords: '3895,2600,50,100' },
    { id: '121', name: 'B: 11', coords: '3945,2500,50,100' },
    { id: '122', name: 'B: 12', coords: '3945,2600,50,100' },
    { id: '123', name: 'B: 13', coords: '4120,2510,50,100' },
    { id: '124', name: 'B: 14', coords: '4120,2610,50,100' },
    { id: '125', name: 'B: 15', coords: '4170,2510,50,100' },
    { id: '126', name: 'B: 16', coords: '4170,2610,50,100' },
    { id: '127', name: 'B: 17', coords: '4380,2285,100,50' },
    { id: '128', name: 'B: 18', coords: '4485,2290,100,50' },
    { id: '129', name: 'B: 19', coords: '4390,2035,100,50' },
    { id: '130', name: 'B: 20', coords: '4490,2035,100,50' },
    { id: '131', name: 'B: 21', coords: '4390,2085,100,50' },
    { id: '132', name: 'B: 22', coords: '4490,2085,100,50' },
    { id: '133', name: 'C: 1', coords: '4390,1765,100,50' },
    { id: '134', name: 'C: 2', coords: '4490,1765,100,50' },
    { id: '135', name: 'C: 3', coords: '4390,1815,100,50' },
    { id: '136', name: 'C: 4', coords: '4490,1815,100,50' },
    { id: '137', name: 'C: 5', coords: '4390,1090,100,50' },
    { id: '138', name: 'C: 6', coords: '4490,1090,100,50' },
    { id: '139', name: 'C: 7', coords: '4390,1140,100,50' },
    { id: '140', name: 'C: 8', coords: '4490,1140,100,50' },
    { id: '141', name: 'C: 9', coords: '4360,590,100,50' },
    { id: '142', name: 'C: 10', coords: '4460,590,100,50' },
    { id: '143', name: 'C: 11', coords: '4360,640,100,50' },
    { id: '144', name: 'C: 12', coords: '4460,640,100,50' },
    { id: '145', name: 'C: 13', coords: '4230,175,50,100' },
    { id: '146', name: 'C: 14', coords: '4230,275,50,100' },
    { id: '147', name: 'C: 15', coords: '4280,175,50,100' },
    { id: '148', name: 'C: 16', coords: '4280,275,50,100' },
    { id: '149', name: 'C: 17', coords: '3840,500,100,50' },
    { id: '150', name: 'C: 18', coords: '3940,500,100,50' },
    { id: '151', name: 'C: 19', coords: '3840,550,100,50' },
    { id: '152', name: 'C: 20', coords: '3940,550,100,50' }
]

export default deskConfig5OG;