import React, { useState, useEffect, useMemo } from "react";
import { Button, Section } from "react-bulma-components";
import { useMsal } from "@azure/msal-react";
import { useLocation } from 'react-router-dom';
import moment from "moment";
import AreaSelect from './BookingForm/AreaSelect';
import useAreaSelection from '../hooks/useAreaSelection';
import { addDays } from "date-fns";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import 'bulma/css/bulma.min.css';
import 'bulma-extensions/bulma-tooltip/dist/css/bulma-tooltip.min.css';
import useAxiosWithToken from '../hooks/axiosTokenHook';
import { formatDateISO, formatDateUI } from '../utils/utils';
import useTooltipAdjustment from "../hooks/useTooltipAdjustment";

const AdvancedBookingOverviewComponent = () => {
  const axios = useAxiosWithToken();
  useTooltipAdjustment();

  const [isLoading, setIsLoading] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [errors] = useState({ location: '' });
  const { accounts } = useMsal();
  const userAccount = accounts[0] || null;
  const user = userAccount ? userAccount.username : "No User";
  const [dateRange, setDateRange] = useState([{
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  }]);
  const [areas, setAreas] = useState([]);
  const { area, areaTitle, handleArea, listAreas, handleAreaViaId } = useAreaSelection(areas);
  const [desks, setDesks] = useState([]);
  const location = useLocation();
  const initialState = location.state;

  useEffect(() => {
    if (initialState && areas.length > 0) {
      const { areaId, startDate, endDate } = initialState;
      setDateRange([{
        startDate: startDate,
        endDate: endDate,
        key: 'selection'
      }]);
      const selectedArea = areas.find(area => area.id === areaId);
      if (selectedArea) {
        handleAreaViaId(selectedArea);
      }
    }
  }, [initialState, areas]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const date = formatDateISO(new Date());
        const [desksResponse, areasResponse, bookingsResponse] = await Promise.all([
          axios.get("desks"),
          axios.get("areas"),
          axios.get(`bookings?startDate=${date}&endDate=${date}`)
        ]);
        setDesks(desksResponse.data);
        setAreas(areasResponse.data);
        setBookings(bookingsResponse.data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [axios]);

  useEffect(() => {
    let startDateFormatted = formatDateISO(moment(dateRange[0].startDate));
    let endDate = dateRange[0].endDate ? dateRange[0].endDate : dateRange[0].startDate;
    let endDateFormatted = formatDateISO(moment(endDate));
    setIsLoading(true);
    axios.get(`bookings?startDate=${startDateFormatted}&endDate=${endDateFormatted}`)
      .then(({ data }) => {
        setBookings(data);
      })
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  }, [dateRange, axios]);

  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
  };

  function getDeskClass(booking) {
    if (booking.username === user) return 'is-black';
    if (booking.username) return 'is-danger';
    return 'is-success';
  }

  function getTooltipText(booking) {
    return booking ? `Booked by ${booking.username}` : 'Desk available';
  }

  const DataTable = useMemo(() => {
    const startDate = moment(dateRange[0].startDate);
    const endDate = dateRange[0].endDate ? moment(dateRange[0].endDate) : moment(dateRange[0].startDate);
    let dateList = [];
    for (let m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'days')) {
      dateList.push(formatDateISO(m));
    }
    return dateList.map(date => {
      const reservationsForDate = bookings.filter(booking => formatDateISO(moment(booking.date)) === date);
      let titleDate = formatDateUI(moment(date));
      let weekday = moment(date).format('ddd');
      titleDate = `${weekday} ${titleDate}`;
      const isWeekend = ['Sat', 'Sun'].includes(weekday);
      const titleClass = isWeekend ? 'dark-grey' : '';
      const allDesksInArea = desks.filter(t => t.area === area);
      const deskElements = allDesksInArea.map((desk, index) => {
        const booking = reservationsForDate.find(b => b.deskid === desk.id);
        let deskClass = booking ? getDeskClass(booking) : 'is-success';
        let tooltipText = getTooltipText(booking);
        return (
          <div
            key={`desk-title-${index}`}
            className={`tag ${deskClass} desk-title`}
            data-tooltip={tooltipText}
          >
            {desk.title}
          </div>
        );
      });
      let title = (
        <div className="is-flex is-align-items-center flex-wrap">
          <span className={`mr-2 ${titleClass}`}>{areaTitle ? `Area ${areaTitle}: ` : ''}{titleDate}</span>
        </div>
      );
      return (
        <React.Fragment key={`accordion-${date}`}>
          <div className="card-header-desk-titles">
            {title}
            {deskElements}
          </div>
          <div className="accordion-line-separator"></div>
        </React.Fragment>
      );
    });
  }, [bookings, desks, area, user, areaTitle, dateRange]);

  return (
    <>
      {isLoading && <progress className="progress is-large is-info" max="100">Loading...</progress>}
      <>
        <Section>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <div style={{ flex: 1 }}>
              <DateRangePicker
                onChange={handleSelect}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={3}
                ranges={dateRange}
                direction="horizontal"
                minDate={new Date()}
                maxDate={addDays(new Date(), 180)}
                staticRanges={[]}
              />
            </div>
            <div style={{ flex: 1 }}>
              <form>
                <AreaSelect area={area} handleArea={handleArea} listAreas={listAreas} error={errors['area']} />
              </form>
            </div>
            <div style={{ display: 'flex', justifyContent: 'right', gap: '10px' }}>
              <Button className="is-success is-small">Available</Button>
              <Button className="is-danger is-small">Unavailable</Button>
              <Button className="is-black is-small" style={{ backgroundColor: 'blue', color: 'white' }}>Your booking</Button>
              <Button className="is-small" style={{ backgroundColor: 'magenta', color: 'white' }}>Admin booking</Button>
            </div>
          </div>
        </Section>
        <Section>
          <div className="columns">
            <div className="column is-9" style={{ marginTop: "60px" }}>
              <div>{DataTable}</div>
            </div>
          </div>
        </Section>
      </>
    </>
  );
};

export default AdvancedBookingOverviewComponent;
