import React, { createContext, useContext, useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig"; // Ensure the path is correct

const MsGraphTokenContext = createContext(null);
export const useMsGraphToken = () => useContext(MsGraphTokenContext);

export const MsGraphTokenProvider = ({ children }) => {
    const { instance, inProgress, accounts } = useMsal();
    const [msGraphToken, setMsGraphToken] = useState(null);

    const acquireToken = async (forceRefresh = false) => {
        if (accounts.length > 0) {
            instance.setActiveAccount(accounts[0]);

            try {
                const response = await instance.acquireTokenSilent({
                    ...loginRequest,
                    account: accounts[0],
                    forceRefresh: forceRefresh, // Optionally force refresh
                });
                setMsGraphToken(response.accessToken);
                return response.accessToken;
            } catch (error) {
                console.error("Error acquiring MS Graph token:", error);
                throw error;
            }
        }
    };

    useEffect(() => {
        if (inProgress === "none") {
            acquireToken();
        }
    }, [instance, inProgress, accounts]);

    const tokenProvider = {
        token: msGraphToken,
        refreshToken: () => acquireToken(true) // Force refresh the token
    };

    return (
        <MsGraphTokenContext.Provider value={tokenProvider}>
            {children}
        </MsGraphTokenContext.Provider>
    );
};
