import React, { useContext, useState, useEffect, useCallback } from "react";
import { Button, Section, Form } from "react-bulma-components";
import Calendar from "react-calendar";
import { useNavigate } from 'react-router-dom';
import BookingForm from "./bookingform.component";
import { useMsal } from "@azure/msal-react";
import useAxiosWithToken from '../../hooks/axiosTokenHook';
import { useUser } from '../../contexts/UserContext';
import { formatDateISO } from "../../utils/utils";
import { confirmAlert } from 'react-confirm-alert';

import SearchInput from "../../services/SearchInput";
import FloorPlan from "../../services/floorplan/FloorPlan";

import { validateAdminSingleBookingForm, prepareBookingRequestsForAdmin, formatDateUI } from "../../utils/utils";
import { TokenContext } from "../../contexts/TokenContext";

import 'bulma/css/bulma.css';
import 'bulma-tooltip/dist/css/bulma-tooltip.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

const AdminSpecialComponent = () => {
  const axios = useAxiosWithToken();
  const token = useContext(TokenContext);
  const { accounts } = useMsal();
  const userAccount = accounts[0] || null;
  const user = userAccount ? userAccount.username : "No User";
  const { isAdmin } = useUser();

  const [isLoading, setIsLoading] = useState(false);
  const [areas, setAreas] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const navigate = useNavigate();
  const [actualDate, onChange] = useState(new Date());
  const [desks, setDesks] = useState([]);
  const [selectedFloor, setSelectedFloor] = useState('5');
  const [desk, setDesk] = useState();
  const [combinedRequests, setCombinedRequests] = useState([]);
  const [selectedArea, setSelectedArea] = useState(null);
  const [deskName, setDeskName] = useState();

  const [errors, setErrors] = useState([]);
  const [userEmail, setUserEmail] = useState();
  const [comment, setComment] = useState();
  const [allBookings, setAllBookings] = useState([]);
  const [bookingConfirmed, setBookingConfirmed] = useState(false);

  const fetchAllBookings = useCallback(() => {
    setIsLoading(true);
    return axios.get('bookings')
      .then(({ data }) => {
        setAllBookings(data);
        setBookings(data);  // Ensure bookings state is updated
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [token]);

  useEffect(() => {
    if (!isAdmin) {
      navigate("/");
      return;
    }
  }, [isAdmin]);

  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      Promise.all([
        axios.get("desks"),
        axios.get("areas")
      ])
        .then(([desksResponse, areasResponse]) => {
          setDesks(desksResponse.data);
          setAreas(areasResponse.data);
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    fetchData();
    console.log(desks);
  }, [navigate]);

  useEffect(() => {
    setIsLoading(true);
    axios.get(`bookings?date=${formatDateISO(actualDate)}`)
      .then(({ data }) => setBookings(data))
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  }, [actualDate]);

  const toggleFormSection = () => {
    setFormVisible(currentVisibility => !currentVisibility);
  };

  useEffect(() => {
    if (userEmail !== undefined) {
      sessionStorage.setItem(`bookingInput_${desk}_${actualDate}`, userEmail);
    }
  }, [userEmail, actualDate, desk]);

  useEffect(() => {
    if (comment !== undefined) {
      sessionStorage.setItem(`bookingInput_comment_${desk}_${actualDate}`, comment);
    }
  }, [comment, actualDate, desk]);

  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [bookingToDelete, setBookingToDelete] = useState();

  const handleAreaClick = (deskObj) => {
    setDesk(deskObj.id);
    setSelectedArea(deskObj.id);
    setDeskName(deskObj.name);

    // Convert both values to string for comparison
    const existingBooking = bookings
      .filter(b => b.id !== null)
      .find(booking => String(booking.deskid) === String(deskObj.id)) ?? null;

    if (existingBooking) {
      setUserEmail(existingBooking.username);
      setComment(existingBooking.comment);
      setShowDeleteButton(true);
      setBookingToDelete(existingBooking);
    } else {
      setUserEmail('');
      setComment('');
      setShowDeleteButton(false);
      setBookingToDelete();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let username = userEmail || undefined;

    const formErrors = validateAdminSingleBookingForm(desk, username);

    if (Object.keys(formErrors).length === 0) {
      fetchAllBookings()
        .then(() => {
          console.log('After fetchAllBookings, allBookings:', allBookings);
          let combinedErrors = [];
          let allRequests = [];
          const { requests, bookingErrors } = prepareBookingRequestsForAdmin(username, desk, actualDate, allBookings, comment, user);

          combinedErrors = [...combinedErrors, ...bookingErrors];
          allRequests = [...allRequests, ...requests];

          setCombinedRequests([...combinedRequests, ...allRequests]);
          if (combinedErrors.length > 0) {
            confirmAlert({
              title: 'Attention!',
              message: `${combinedErrors}`,
              buttons: [
                {
                  label: 'OK',
                  onClick: () => { }
                }
              ]
            });
            console.error("Combnied Errors", combinedErrors);
          } else {
            confirmAlert({
              title: 'Confirm Booking',
              message: `Do you want to book desk with name: ${deskName} on the ${formatDateUI(actualDate)} for ${username}?`,
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    setSelectedArea(desk);
                    setBookingConfirmed(true); // Set bookingConfirmed to true
                  }
                },
                {
                  label: 'No',
                  onClick: () => { }
                }
              ]
            });
          }
        })
        .catch(error => {
          console.error('Error fetching bookings:', error);
        });
    } else {
      setErrors(formErrors);
      console.log("Form Errors:", formErrors);
    }
  };

  useEffect(() => {
    if (bookingConfirmed && combinedRequests.length > 0) {
      console.log("Proceeding with booking:", combinedRequests);
      Promise.all(combinedRequests.map(request =>
        axios.post('bookings', request).catch(error => ({ error }))
      ))
        .then(responses => {
          const submissionErrors = responses.filter(response => response.error);
          console.log("Booking Responses:", responses);
          if (submissionErrors.length > 0) {
            confirmAlert({
              title: 'Attention!',
              message: `There is already a booking by someone else. Please reload the page.`,
              buttons: [
                {
                  label: 'OK',
                  onClick: () => { }
                }
              ]
            });
          } else {
            fetchAllBookings()
              .then(() => {
                confirmAlert({
                  title: 'Booking successful!',
                  message: `Booking successfully`,
                  buttons: [
                    {
                      label: 'OK',
                      onClick: () => { }
                    }
                  ]
                });
                setUserEmail('');
                setComment('');
                setShowDeleteButton(false);
                setSelectedArea(null);
                setCombinedRequests([]); // Clear combinedRequests after booking
                setBookingConfirmed(false); // Reset bookingConfirmed
              })
              .catch(error => {
                console.error('Error fetching bookings:', error);
                setBookingConfirmed(false);
              });
          }
        })
        .catch(error => {
          setBookingConfirmed(false); // Reset bookingConfirmed
          console.error('Error during booking process:', error);
          confirmAlert({
            title: 'Attention!',
            message: `Something went wrong during the booking process.`,
            buttons: [
              {
                label: 'OK',
                onClick: () => { }
              }
            ]
          });

        });
    }
  }, [bookingConfirmed, combinedRequests, fetchAllBookings]);

  const deleteBooking = async () => {
    try {
      const result = await axios.delete(`bookings/${bookingToDelete.id}`);
      if (result.status === 200) {
        await fetchAllBookings(); // Refetch all bookings after deletion
        setSelectedArea(null);
        setUserEmail('');
        setComment('');
        confirmAlert({
          title: 'Deletion successful!',
          message: `Booking deleted successfully`,
          buttons: [
            {
              label: 'OK',
              onClick: () => { }
            }
          ]
        });
      } else {
        throw new Error("Failed to delete booking");
      }
    } catch (error) {
      console.error('Error deleting the booking:', error);
      confirmAlert({
        title: 'Attention!',
        message: `Something went wrong deleting the booking (209)`,
        buttons: [
          {
            label: 'OK',
            onClick: () => { }
          }
        ]
      });
    }
  };

  const confirmDeleteBooking = async () => {
    confirmAlert({
      title: 'Confirm Deletion of Booking',
      message: `Do you want to delete the booking for desk ${bookingToDelete.desktitle} on the ${formatDateUI(bookingToDelete.date)} for ${bookingToDelete.username}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            try {
              deleteBooking();
            } catch (error) {
              console.error('Error booking the desk:', error);
            }
          }
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });
  };

  const updateBookingComment = async () => {
    // Assuming formatDateUI returns a string in the format "YYYY-MM-DD"
    const matchingBooking = bookings.find(booking => {
      return String(booking.deskid) === String(desk) && booking.date === formatDateISO(actualDate);
    });
    const bookingId = matchingBooking ? matchingBooking.id : undefined;
    if (bookingId !== undefined) {
      const result = await axios.put(`bookings/${bookingId}`, { user, comment });
      if (result.status === 200 || result.status === 204) {
        confirmAlert({
          title: 'Update successful!',
          message: `Comment updated successfully`,
          buttons: [
            {
              label: 'OK',
              onClick: () => { }
            }
          ]
        });
        fetchAllBookings();
      }
    }
  };

  const FloorDropdown = ({ selectedFloor, handleFloorChange }) => (
    <div className="columns is-vcentered" style={{ paddingTop: '20px' }}>
      <div className="column is-one-quarter has-text-left">
        <label className="label">Floor</label>
        <div className="select">
          <select value={selectedFloor} onChange={handleFloorChange}>
            <option value="5">5th Floor</option>
            <option value="6">6th Floor</option>
          </select>
        </div>
      </div>
    </div>
  );

  const handleFloorChange = (event) => {
    setSelectedFloor(event.target.value);
  };

  return (
    <>
      {isLoading && <progress className="progress is-large is-info" max="100">Loading...</progress>}
      <div className="columns">
        <div className="column is-offset-6 is-6">
          <div className="buttons are-small is-pulled-right" style={{ marginTop: '50px', marginBottom: '20px' }}>
            <Button className="is-success">Available</Button>
            <Button className="is-danger">Unavailable</Button>
            <Button className="is-blue" style={{ backgroundColor: 'blue', color: 'white', border: 'none' }}>Your booking</Button>
            <Button className="is-orange" style={{ backgroundColor: 'orange', color: 'white', border: 'none' }}>
              Selected Desk
            </Button>
            <Button style={{ backgroundColor: 'magenta', color: 'white' }}>Admin booking</Button>
          </div>
        </div>
      </div>
      <Section>
        <div className="has-text-left" style={{ marginTop: '10px', marginBottom: '70px' }}>
          <Button className="button-custom" onClick={toggleFormSection}>
            Multiple bookings
          </Button>
        </div>
        {formVisible &&
          <div className="booking-form-container">
            <BookingForm key={formVisible} areas={areas} setFormVisible={setFormVisible} />
          </div>
        }

      </Section>
      <Section>
        <h1>Single booking</h1>
      <div className="columns is-vcentered" style={{ paddingTop: '20px' }}>
        <div className="column is-half is-offset-one-quarter has-text-centered">
          <Calendar onChange={onChange} value={actualDate} minDate={new Date()} />
        </div>
      </div>
      <FloorDropdown selectedFloor={selectedFloor} handleFloorChange={handleFloorChange} />
      <FloorPlan
        selectedFloor={selectedFloor}
        bookings={bookings}
        handleAreaClick={handleAreaClick}
        selectedArea={selectedArea}
        user={user}
          date={actualDate}

      />
        {errors['desk'] && <div style={{ color: 'red' }}>{errors['desk']}</div>}

        <form onSubmit={handleSubmit} className="form">
          <Form.Field kind="group">
            <SearchInput value={userEmail} onChange={setUserEmail} errors={errors} />
            <Form.Control fullwidth>
              <Form.Label htmlFor={`comment`}>Comment (optional)</Form.Label>
              <Form.Input
                id={`comment`}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Enter an optional comment"
              />
            </Form.Control>
          </Form.Field>
          <div>
            {!showDeleteButton && <Button type="submit" size="medium" align="center" color="warning">Submit</Button>}
            {showDeleteButton && <Button type="button" size="medium" align="center" color="warning" onClick={updateBookingComment}>Update Comment for Booking</Button>}
            {showDeleteButton && <Button type="button" size="medium" align="center" color="danger" onClick={confirmDeleteBooking}>Delete Booking</Button>}
          </div>
        </form>
      </Section>
    </>
  );
};

export default AdminSpecialComponent;
