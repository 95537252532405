
import moment from 'moment';

export const getEndDate = (startDate, frequency, checkedDays) => {
  let current = moment(startDate);
  const daysToAdd = frequency * 7;
  let endDate = moment(current).add(daysToAdd, 'days');

  // Adjust end date based on checked days
  while (!checkedDays[endDate.day()]) {
    endDate.add(1, 'day');
  }

  return formatDateISO(endDate);
};

export const validateBookingForm = (area, desk, checkedDays, startDate, endDate) => {
  const formErrors = {};
  if (!area) {
    formErrors['area'] = 'Please select an area';
  }
  if (!desk) {
    formErrors['desk'] = 'Please select a desk. A selected desk is shown in orange.';
  }
  if (checkedDays.every(day => !day)) {
    formErrors['days'] = 'Select at least one day.';
  }
  const maxDate = moment().add(180, 'days');
  if (moment(formatDateISO(endDate)) > maxDate) {
    const numberOfWeeks = maxDate.diff(startDate, 'weeks');
    formErrors['endDate'] = `Maximum of allowed weeks: ${numberOfWeeks}. Latest allowed booking Date: ${formatDateUI(maxDate)}.`;
  }

  return formErrors;
};



export const validateAdminBookingForm = (area, desk, checkedDays, email) => {
  const errors = {};
  if (!area) {
    errors['area'] = 'Please select an area';
  }
  if (!desk) {
    errors['desk'] = 'Please select a desk. A selected desk is shown in orange.';
  }
  if (checkedDays.every(day => !day)) {
    errors['days'] = 'Select at least one day.';
  }
  if (!email) {
    errors['email'] = 'Provide a Name or E-Mail-Address.';
  }
  return errors;
};


export const validateAdminSingleBookingForm = (desk, email) => {
  const errors = {};
  if (!desk) {
    errors['desk'] = 'Please select a desk. A selected desk is shown in orange.';
  }
  if (!email) {
    errors['email'] = 'Provide a Name or E-Mail-Address.';
  }
  return errors;
};

export const prepareBookingRequests = (username, tableId, bookingDate, allBookings, comment) => {
  const requests = [];
  const bookingErrors = [];

  const formattedBookingDate = formatDateISO(bookingDate);

  const conflictingBooking = allBookings.find(b => {
    return b.username === username &&
    //b.isAdmin === 0 &&
      (formatDateISO(b.date) === formattedBookingDate) 
  });


  let conflictingBookingTable = allBookings.find(b =>
    b.deskid === parseInt(tableId) &&
    formatDateISO(moment(b.date)) === formattedBookingDate

  );

  if (conflictingBooking) {
    bookingErrors.push(`Booking conflict for ${formatDateUI(formattedBookingDate)} with ${conflictingBooking.username}.`);
  } else if (conflictingBookingTable) {
    bookingErrors.push(`Booking conflict for ${formatDateUI(formattedBookingDate)} with ${conflictingBookingTable.username}.`);
  } else {

    requests.push({
      username,
      deskid: tableId,
      date: formattedBookingDate,
      lastModifiedBy: username,
      bookedBy: username,
      comment: comment,
    });

  }

  return { requests, bookingErrors };
};

export const formatDateISO = (date) => {

  return moment(date).format('YYYY-MM-DD');
}

export const formatDateUI = (date) => {
  return moment(date).format('DD.MM.YYYY');
}

export function getDateFromUiString(dateStr) {
  // Split the string into parts
  const parts = dateStr.split('.');

  // Note: months are 0-based
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Subtract 1 to make the month 0-based
  const year = parseInt(parts[2], 10);

  // Create a new Date object
  return new Date(year, month, day);
}

export const prepareBookingRequestsForAdmin = (username, tableId, bookingDate, allBookings, comment, adminUser) => {
  const requests = [];
  const bookingErrors = [];

  const formattedBookingDate = formatDateISO(moment(bookingDate));

  let conflictingBookingTable = allBookings.find(b =>
    b.deskid === parseInt(tableId) &&
    formatDateISO(moment(b.date)) === formattedBookingDate

  );

  if (conflictingBookingTable) {
    bookingErrors.push(`Booking conflict for ${formatDateUI(formattedBookingDate)} with ${conflictingBookingTable.username}.`);
  } else {

    requests.push({
      username,
      deskid: tableId,
      date: formattedBookingDate,
      lastModifiedBy: adminUser,
      bookedBy: adminUser,
      comment: comment,
      isAdminBookingType: 1
    });

  }

  return { requests, bookingErrors };
};
