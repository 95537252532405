import React from 'react';

const AreaSelect = ({ area, handleArea, listAreas, error }) => {
  return (
    <div className="field">
      <label className="label">Select Area</label>
      <div className="control">
        <div className="select is-normal">
          <select onChange={handleArea} value={area}>
            <option value="">Select Area</option>
            {listAreas()}
          </select>
        </div>
      </div>
      {error && <p className="help is-danger">{error}</p>}
    </div>
  );
};

export default AreaSelect;
