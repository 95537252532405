const deskConfig5OG_B = [
    { id: '111', name: 'B: 1', coords: '100,600,50,100' },
    { id: '112', name: 'B: 2', coords: '100,700,50,100' },
    { id: '113', name: 'B: 3', coords: '150,600,50,100' },
    { id: '114', name: 'B: 4', coords: '150,700,50,100' },
    { id: '115', name: 'B: 5', coords: '315,570,50,100' },
    { id: '116', name: 'B: 6', coords: '315,670,50,100' },
    { id: '117', name: 'B: 7', coords: '365,570,50,100' },
    { id: '118', name: 'B: 8', coords: '365,670,50,100' },
    { id: '119', name: 'B: 9', coords: '400,890,50,100' },
    { id: '120', name: 'B: 10', coords: '400,990,50,100' },
    { id: '121', name: 'B: 11', coords: '450,890,50,100' },
    { id: '122', name: 'B: 12', coords: '450,990,50,100' },
    { id: '123', name: 'B: 13', coords: '630,880,50,100' },
    { id: '124', name: 'B: 14', coords: '630,980,50,100' },
    { id: '125', name: 'B: 15', coords: '680,880,50,100' },
    { id: '126', name: 'B: 16', coords: '680,980,50,100' },
    { id: '127', name: 'B: 17', coords: '885,680,100,50' },
    { id: '128', name: 'B: 18', coords: '990,680,100,50' },
    { id: '129', name: 'B: 19', coords: '890,440,100,50' },
    { id: '130', name: 'B: 20', coords: '990,440,100,50' },
    { id: '131', name: 'B: 21', coords: '890,490,100,50' },
    { id: '132', name: 'B: 22', coords: '990,490,100,50' }
]

export default deskConfig5OG_B