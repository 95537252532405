import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { getMsalConfig } from './authConfig';

import { BrowserRouter } from "react-router-dom";

const msalInstance = new PublicClientApplication(getMsalConfig());

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
          <BrowserRouter>
              <App />
          </BrowserRouter>
        </MsalProvider>
    </React.StrictMode>
);
