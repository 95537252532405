import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Import the CSS

const WEEK_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const DaySelect = ({ checkedDays, handleDayToggle, onDateChange, error }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [initialCheckedDays] = useState([false, false, false, false, false, false, false]);


  // Update the checkedDays state when the component mounts
  useEffect(() => {
    handleDayToggle(initialCheckedDays);
  }, []);



  // Calculate the maximum date (180 days from now)
  const maxDate = moment().add(180, 'days').toDate();

  const handleManualDateChange = (newDate) => {
    setSelectedDate(newDate);
    onDateChange(newDate);
  };

  const renderDayCheckboxes = () => {
    return WEEK_DAYS.map((day, index) => (
      <label key={day} className="checkbox mr-2">
        <input
          type="checkbox"
          checked={checkedDays[index]}
          onChange={() => handleDayToggle(index)}
        /> {day}
      </label>
    ));
  };

  return (
    <div>
      <div className="field">
        <label className="label">Start Date</label>
        <div className="control">
          <Calendar
            value={selectedDate}
            onChange={handleManualDateChange}
            minDate={new Date()}
            maxDate={maxDate}
          />
        </div>
      </div>

      <div className="field">
        <label className="label">Select Days</label>
        <div className="control">
          {renderDayCheckboxes()}
        </div>
      </div>
      {error && <p className="help is-danger">{error}</p>}
    </div>
  );
};

export default React.memo(DaySelect);
