import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { Table, Button, Section, Form } from 'react-bulma-components';
import { useMsal } from "@azure/msal-react";
import DeleteConfirmationComponent from './delete-confirmation.component';
import useAxiosWithToken from '../hooks/axiosTokenHook';
import { formatDateISO, formatDateUI } from '../utils/utils';

const BookingListComponent = () => {

  const axios = useAxiosWithToken();

  // State management
  const [bookings, setBookings] = useState([]);
  const [valid, setValid] = useState(true);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedBookings, setSelectedBookings] = useState([]);
  const [filteredBookingIds, setFilteredBookingIds] = useState([]);

  // User
  const { accounts } = useMsal();
  const userAccount = accounts[0] || null;
  const user = userAccount ? userAccount.name : "No User";
  const userEmail = userAccount ? userAccount.username : "No User";

  // Loading progress bar state
  const [isLoading, setIsLoading] = useState(false);

  const monthOptions = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  // Filters for filtering bookings
  const [filters, setFilters] = useState({
    month: '',
    floor: '',
    area: '',
    desk: ''
  });

  const [floorOptions, setFloorOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [deskOptions, setDeskOptions] = useState([]);


  const location = useLocation();
  const initialState = location.state;

  // Extract and apply filters from navigation state on component mount
  useEffect(() => {
    if (initialState) {
      resetFilters();
      const { area, table } = initialState;
      setFilters({
        area: area || '',
        desk: table || '',
      });
      updateFloorOptions(bookings, "", area);
    }
  }, [initialState]);

  useEffect(() => {
    // Update area options based on selected floor and month
    const fillteredAreas = bookings
      .filter(booking =>
        (!filters.floor || booking.floor === filters.floor) &&
        (!filters.month || moment(booking.date).format('MM') === filters.month)
      )
      .map(booking => booking.areatitle);

    // Update desk options based on selected area, floor, and month
    const filteredDesks = bookings
      .filter(booking =>
        (!filters.floor || booking.floor === filters.floor) &&
        (!filters.area || booking.areatitle === filters.area) &&
        (!filters.month || moment(booking.date).format('MM') === filters.month)
      )
      .map(booking => booking.desktitle);

    setAreaOptions([...new Set(fillteredAreas)]);
    setDeskOptions([...new Set(filteredDesks)]);
  }, [bookings, filters]);

  useEffect(() => {
    setIsLoading(true);
    try {
      axios.get(`bookings?username=${userEmail}`)
        .then((response) => {
          if (response.data) {
            setBookings(response.data);
            updateFloorOptions(response.data, filters.month, filters.area);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (error) {
      console.error(error);
    }

  }, [user, valid, filters, axios]);

  const updateFilteredBookingIds = () => {
    const filtered = getFilteredBookings();
    setFilteredBookingIds(filtered.map(booking => booking.id));
  };

  const handleSelectAll = (e) => {
    setSelectedBookings(e.target.checked ? filteredBookingIds : []);
  };

  useEffect(() => {
    updateFilteredBookingIds();
  }, [bookings, filters]);

  // Update for area and desk dropdowns
  const areaDropdownOptions = areaOptions.map(area => <option key={area} value={area}>{area}</option>);
  const deskDropdownOptions = deskOptions.map(desk => <option key={desk} value={desk}>{desk}</option>);

  const resetFilters = () => {
    setFilters({
      month: '',
      floor: '',
      area: '',
      desk: ''
    });
  };

  const handleFilterChange = (e, filterType) => {
    const newFilters = { ...filters, [filterType]: e.target.value };

    if (filterType === 'month' || filterType === 'area') {
      updateFloorOptions(bookings, newFilters.month, newFilters.area);
      if (filterType === 'area') {
        newFilters.floor = ''; // Reset floor when area changes
      }
      newFilters.desk = ''; // Reset desk when month or area changes
    } else if (filterType === 'floor') {
      newFilters.desk = ''; // Reset desk when floor changes
    }

    setFilters(newFilters);
  };


  const updateFloorOptions = (bookingsData, selectedMonth, selectedArea) => {
    let filteredFloors = bookingsData;

    if (selectedMonth) {
      filteredFloors = filteredFloors.filter(booking => moment(booking.date).format('MM') === selectedMonth);
    }

    if (selectedArea) {
      filteredFloors = filteredFloors.filter(booking => booking.areatitle === selectedArea);
    }

    setFloorOptions([...new Set(filteredFloors.map(booking => booking.floor))]);
  };


  // Get filtered bookings based on selected filters
  const getFilteredBookings = () => {
    return bookings.filter(booking => {
      const bookingMonth = moment(booking.date).format('MM');
      return (!filters.month || bookingMonth === filters.month) &&
        (!filters.floor || booking.floor === filters.floor) &&
        (!filters.area || booking.areatitle === filters.area) &&
        (!filters.desk || booking.desktitle === filters.desk);
    }).sort((a, b) => moment(a.date).diff(moment(b.date)));
  };

  // Toggle selection of bookings
  const toggleBooking = (id) => {
    setSelectedBookings(
      selectedBookings.includes(id)
        ? selectedBookings.filter((selectedId) => selectedId !== id)
        : [...selectedBookings, id]
    );
  };

  // Handle booking deletion
  const deleteBooking = (booking) => {
    setIsConfirmationModalOpen(true);
    setSelectedBookings(
      Array.isArray(booking) ? booking :
        booking && typeof booking === 'object' && booking.id ? [booking.id] :
          console.error('Invalid booking data', booking)
    );
  };

  const confirmDeleteBooking = () => {
    if (selectedBookings.length > 0) {
      selectedBookings.forEach(bookingId => {
        // Ensure only bookings visible and selected get deleted
        if (filteredBookingIds.includes(bookingId)) {
          axios.delete(`bookings/${bookingId}`)
            .then((result) => {
              if (result.status === 200) {
                setValid(!valid);
              } else {
                Promise.reject();
              }
            })
            .catch(() => alert("Something went wrong deleting the booking (225)"));
        }
      });
      setIsConfirmationModalOpen(false);
    } 0;
  }

  const getRowClass = (res) => {
    const isToday = res.date === formatDateISO(moment());
    const isAdmin = res.isAdmin === 1;
    if (isToday && isAdmin) {
      return "active has-background-grey-lighter";
    } else if (isToday) {
      return "active";
    } else if (isAdmin) {
      return "has-background-grey-lighter";
    } else {
      return "";
    }
  };

  // Check if all bookings are selected
  const isAllSelected = () => bookings.length > 0 && selectedBookings.length === bookings.length;

  // Render the data table
  const DataTable = () => {
    const filteredBookings = getFilteredBookings();
    return filteredBookings.map((res) => {
      let dateformat = formatDateUI(moment(res.date));
      return (
        <tr key={res.id} className={getRowClass(res)}>
          <td data-label="Select">
            <input
              id={res.id}
              type="checkbox"
              onChange={() => toggleBooking(res.id)}
              checked={selectedBookings.includes(res.id)}
            />
          </td>
          <td data-label="Date">{dateformat}</td>
          <td data-label="Floor">{res.floor}</td>
          <td data-label="Area">{res.areatitle}</td>
          <td data-label="Desk">{res.desktitle}</td>
          <td data-label="Comment by Admin" className={res.comment ? "" : "empty-comment"}>
            {res.comment || " "}
          </td>
          <td data-label="Actions">
            <Button onClick={() => deleteBooking(res)} size="small" color="danger">
              Delete
            </Button>
          </td>
        </tr>
      );
    });
  };

  // Component render
  return (
    <>
      {isLoading && <progress className="progress is-large is-info" max="100">Loading...</progress>}
      <p> </p>
      <>
        <Section>
          <p>{`${user}, these are your reservations!`}</p>
          <div style={{ display: 'flex', justifyContent: 'right', gap: '10px' }}>
            <Button className="is-small" style={{ backgroundColor: '#d3d3d3', color: 'black' }}>Admin booking</Button>
          </div>
        </Section>
        <Section className="table-wrapper">
          <Table striped bordered hoverable className="is-fullwidth">
            <thead>
              <tr className="is-selected">
                <th>
                  <div className="table-header-controls">
                    <Button onClick={() => deleteBooking(selectedBookings)} color="danger">
                      Delete selected
                    </Button>
                    <p>
                      <input type="checkbox" onChange={handleSelectAll} checked={isAllSelected()} />
                    </p>
                  </div>
                </th>
                <th>
                  <p>
                    <b>Month:</b>
                  </p>
                  <Form.Select
                    value={filters.month}
                    onChange={(e) => handleFilterChange(e, 'month')}
                  >
                    <option value="">All Months</option>
                    {monthOptions.map(month =>
                      <option key={month.value} value={month.value}>{month.label}</option>
                    )}
                  </Form.Select>
                </th>
                <th>
                  <p>
                    <b>Floor:</b>
                  </p>
                  <Form.Select
                    value={filters.floor}
                    onChange={(e) => handleFilterChange(e, 'floor')}
                  >
                    <option value="">All Floors</option>
                    {floorOptions.map(floor => <option key={floor} value={floor}>{floor}</option>)}
                  </Form.Select>
                </th>
                <th>
                  <p>
                    <b>Area:</b>
                  </p>
                  <Form.Select
                    value={filters.area}
                    onChange={(e) => handleFilterChange(e, 'area')}
                  >
                    <option value="">All Areas</option>
                    {areaDropdownOptions}
                  </Form.Select>
                </th>
                <th>
                  <p>
                    <b>Desk Nr.:</b>
                  </p>
                  <Form.Select
                    value={filters.desk}
                    onChange={(e) => handleFilterChange(e, 'desk')}
                  >
                    <option value="">All Desks</option>
                    {deskDropdownOptions}
                  </Form.Select>
                </th>
                <th>Comment by Admin</th>
                <th>
                  <Button onClick={resetFilters} color="info">
                    Reset Filters
                  </Button>
                </th>
              </tr>
            </thead>
            <tbody>{DataTable()}</tbody>
          </Table>
        </Section>
        <DeleteConfirmationComponent
          showConfirmation={isConfirmationModalOpen}
          setShowConfirmation={setIsConfirmationModalOpen}
          onConfirmDelete={confirmDeleteBooking}
          selectedBookings={selectedBookings}
          bookings={bookings}
        />
      </>
    </>
  );
};

export default BookingListComponent;
