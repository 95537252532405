import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import 'bulma/css/bulma.min.css';
import 'react-calendar/dist/Calendar.css';
import "./App.css";

import Layout from "./pages/layout";
import Home from "./pages/home";
import BookingList from "./pages/booking-list";
import Overview from "./pages/overview";
import BookingOverview from "./pages/booking-overview";
import AdvancedBookingOverview from "./pages/booking-overview-advanced";
import NoPage from "./pages/NoPage";
import AdminSpecialBookingPage from "./pages/admin.special";
import LoginPage from "./pages/login";

import { useMsal } from "@azure/msal-react";
import { EventType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";

import { UserProvider } from "./contexts/UserContext";
import AdminStatusSetter from "./services/AdminStatusSetter";

import ProtectedRoute from './utils/ProtectedRoute';
import { ApiTokenProvider } from './contexts/ApiTokenProvider';
import { MsGraphTokenProvider } from './contexts/MsGraphTokenProvider';

export default function App() {
    const { instance, inProgress } = useMsal();

    useEffect(() => {
        document.title = 'Beyond Now Desk Reservation';
    }, []);

    useEffect(() => {
        const handleRedirectPromise = async () => {
            try {
                const response = await instance.handleRedirectPromise();
                if (response && response.eventType === EventType.LOGIN_SUCCESS) {
                    // Handle successful login
                }
            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    await instance.acquireTokenRedirect(loginRequest);
                } else {
                    console.error(error);
                }
            }
        };

        if (inProgress === "none") {
            handleRedirectPromise();
        }
    }, [instance, inProgress]);

    return (
        <ApiTokenProvider>
            <UserProvider>
                <MsGraphTokenProvider>
                    <Routes>
                        <Route path="/" element={<LoginPage />} />
                        <Route path="/app" element={<Layout />}>
                            <Route index element={<Home />} />
                            <Route path="booking-list" element={<BookingList />} />
                            <Route path="shareddesk" element={<Overview />} />
                            <Route path="booking-overview" element={<BookingOverview />} />
                            <Route path="booking-overview-advanced" element={<AdvancedBookingOverview />} />
                            <Route path="admin-booking" element={
                                <ProtectedRoute>
                                    <AdminSpecialBookingPage />
                                </ProtectedRoute>
                            } />
                            <Route path="*" element={<NoPage />} />
                        </Route>
                    </Routes>
                    <AdminStatusSetter />
                </MsGraphTokenProvider>
            </UserProvider>
        </ApiTokenProvider>
    );
}
